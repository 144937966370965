import { AgGridReact } from "ag-grid-react";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { statefedmanagerlist } from "../../../services/state";
import PayUrl from "../../../Apiccavenue";

export const ManagerList = () => {
  const navigate = useNavigate();
  const [filterProduct, setFilterProduct] = useState([]);
  const [cpath, setCPath] = useState("");

  const gridRef = useRef();

  const columnDefs = [
    {
      headerName: "Action",
      width: 80,
      field: "id",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <button
            className="btn-sm btn-info"
            onClick={() => navigate("/state/editmanagerform/" + params.value)}
          >
            <i className="fa fa-eye fa-lg"></i>
          </button>
        );
      },
    },
    {
      headerName: "Photo",
      width: 80,
      field: "photograph",
      filter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <img
            style={{ width: "35px", height: "35px" }}
            src={params.value}
            onError={(e) => errorhandle(e)}
          />
        );
      },
    },
    {
      headerName: "Payment",
      width: 110,
      field: "payment_status",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value === "Pending") {
          return (
            <button
              className="btn-sm btn-success"
              onClick={() => paynow(params)}
            >
              Pay Now
            </button>
          );
        } else {
          return "-";
        }
      },
    },
    {
      headerName: "Payment Status",
      width: 150,
      field: "payment_status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Name",
      width: 200,
      field: "full_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Father Name",
      width: 200,
      field: "father_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Educational Qualification",
      width: 150,
      field: "education_qualification",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Technical Examination Passed",
      width: 150,
      field: "technical_examinationa_passed",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      width: 150,
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile Number",
      width: 130,
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Address",
      width: 200,
      field: "address",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "State",
      width: 150,
      field: "state_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  function paynow(pdata) {
    window.open(
      PayUrl + "state-meet-manager-billing/" + pdata.data.unique_no,
      "_self"
    );
  }

  useEffect(() => {
    statefedmanagerlist().then((response) => {
      if (response.status === true) {
        let arr = [];
        console.log(response.data);
        if (response.data.info.length !== 0) {
          for (let i = 0; i < response.data.info.length; i++) {
            let photo = "";
            if (response.data.info[i].photograph !== null) {
              photo = response.data.path + response.data.info[i].photograph;
            }
            arr.push({
              id: response.data.info[i].id,
              address: response.data.info[i].address,
              area_of_function: response.data.info[i].area_of_function,
              education_qualification:
                response.data.info[i].education_qualification,
              email_id: response.data.info[i].email_id,
              father_name: response.data.info[i].father_name,
              full_name: response.data.info[i].full_name,
              mobile_no: response.data.info[i].mobile_no,
              photograph: photo,
              state_name: response.data.info[i].state_name,
              technical_examinationa_passed:
                response.data.info[i].technical_examinationa_passed,
              payment_status: response.data.info[i].payment_status,
              order_id: response.data.info[i].order_id,
              payment_date: response.data.info[i].payment_date,
              payment_id: response.data.info[i].payment_id,
              status: response.data.info[i].status,
              unique_no: response.data.info[i].unique_no,
            });
          }
          setFilterProduct(arr);
        }
        setCPath(response.data.path);
      }
    });
  }, []);

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/placeholder.png");
    ev.target.onerror = null;
  }

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Meet Manager Course List
          </h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="d-flex justify-content-between">
                <div>
                  {/* <h6 className="main-content-label">Manager Course List</h6> */}
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => navigate("/state/managerform/")}
                  >
                    Add Meet Manager Course Registration Form
                  </button>
                </div>
              </div>
              <div className="responsive mt-2">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
