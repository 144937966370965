import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";

import "../dashboard.scss";
import {
  meetindividuallist,
  meetstatedistrictlist,
} from "../../../services/meetservice";
import { Link } from "react-router-dom";
import { athleteview } from "../../../services/athlete";
import Swal from "sweetalert2";
import imageUrl from "../../../ImageUrl";
import fileString from "../../../assets/language/filepath.json";

function DistrictDashboard() {
  const [filterProduct, setFilterProduct] = useState([]);

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/logo/logo-small.png");
    ev.target.onerror = null;
  }

  const columnDefs = [
    {
      headerName: "Logo",
      width: 80,
      field: "logo",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <img
            style={{ width: "35px", height: "35px" }}
            src={imageUrl + fileString.tournamentfiles + params.data.logo}
            onError={(e) => errorhandle(e)}
          />
        );
      },
    },
    {
      headerName: "Meet Name",
      width: 300,
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Date",
      width: 210,
      field: "start_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },

      // cellRenderer: (data) => {
      //   return moment(data.value).format("DD-MM-YYYY");
      // },
      valueGetter: function sumField(params) {
        return (
          moment(params.data.start_date).format("DD-MM-YYYY") +
          " - " +
          moment(params.data.end_date).format("DD-MM-YYYY")
        );
      },
    },
    {
      headerName: "Entry Open",
      width: 150,
      field: "entry_opens",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value !== null) {
          return moment(data.value).format("DD-MM-YYYY");
        } else {
          return "-";
        }
      },
    },
    {
      headerName: "Entry Closed",
      width: 150,
      field: "entry_deadline_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Actions",
      width: 120,
      field: "id",
      filter: false,
      resizable: true,
      cellRenderer: function (params) {
        if (params.data.sent_status === "Entry Closed") {
          return <a style={{ color: "red" }}>{params.data.sent_status}</a>;
        }else if ((params.data.sent_status === "SUCCESS" || params.data.sent_status == 1) && params.data.meet_type === 8) {
          return (
            <Link
              to={"/district/meetconfirmation/" + params.value}
              className="btn-sm btn-info"
            >
              <i className="fa fa-eye fa-lg"></i>
            </Link>
          );
        } else if (
          (params.data.sent_status === "SUCCESS" || params.data.sent_status == 1) &&
          params.data.meet_type !== 8
        ) {
          return (
            <div>
              <Link
                to={"/district/confirmation/" + params.value}
                className="btn-sm btn-info me-2"
              >
                <i className="fa fa-eye fa-lg"></i>
              </Link>

              {params.data.allow_additional_entry === "Y" &&
                params.data.additionalentry_status === 0 && (
                  <Link
                    to={"/district/additionalentrylist/" + params.value}
                    className="btn-sm btn-success"
                  >
                    <i className="fa fa-user-plus fa-lg"></i>
                  </Link>
                 )}
            </div>
          );
        } else if (
          params.data.sent_status !== "SUCCESS" &&
          params.data.meet_type === 8
        ) {
          return (
            <Link
              to={"/district/athleteentrylist/" + params.value}
              className="btn-sm btn-success"
            >
              <i className="fa fa-user-plus fa-lg"></i>
            </Link>
          );
        } else if (
          params.data.sent_status !== "SUCCESS" &&
          params.data.meet_type !== 8
        ) {
          return (
            <Link
              to={"/district/entrylist/" + params.value}
              className="btn-sm btn-success"
            >
              <i className="fa fa-user-plus fa-lg"></i>
            </Link>
          );
        } 
      },
    },
  ];
  var arr = [];

  useEffect(() => {
    meetstatedistrictlist(localStorage.getItem("State")).then((res) => {
      if (res.status === true) {
        if (res.data.length !== 0) {
          for (let i = 0; i < res.data.length; i++) {
            let butt = "";
            if (res.data[i].sent_status === "SUCCESS" || res.data[i].sent_status == 1) {
              butt = res.data[i].sent_status;
            } else {
              if (
                moment(new Date()).format("YYYY-MM-DD") <=
                moment(res.data[i].entry_deadline_date).format("YYYY-MM-DD")
              ) {
                if (res.data[i].sent_status === "SUCCESS" || res.data[i].sent_status == 1) {
                  butt = res.data[i].sent_status;
                } else {
                  if (
                    moment(res.data[i].entry_opens).format("YYYY-MM-DD") <=
                    moment(new Date()).format("YYYY-MM-DD")
                  ) {
                    butt = 'pending';
                  }
                }
              } else {
                butt = "Entry Closed";
              }
            }
            arr.push({
              id: res.data[i].id,
              meet_name: res.data[i].meet_name,
              start_date: res.data[i].start_date,
              end_date: res.data[i].end_date,
              entry_opens: res.data[i].entry_opens,
              entry_deadline_date: res.data[i].entry_deadline_date,
              sent_status: butt,
              meet_type: res.data[i].meet_type,
              logo: res.data[i].logo,
              allow_additional_entry: res.data[i].allow_additional_entry,
              additionalentry_status: res.data[i].additionalentry_status,
            });
          }
        }
        setFilterProduct(arr);
      }
    });
  }, []);
  return (
    <Fragment>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Welcome to {localStorage.getItem("User")} Dashboard
          </h2>
        </div>
      </div>
      {/* <!-- End Page Header --> */}

      <Row className=" row-sm  mt-lg-4">
        <Col sm={12} lg={12} xl={12}>
          <Card className="bg-black-2 custom-card card-box">
            <Card.Body className="p-4">
              <Row className="align-items-center">
                <Col
                  sm={{ offset: 6 }}
                  xl={{ offset: 3 }}
                  className="col-xl-8 col-sm-6 col-12 img-bg "
                >
                  <h4 className="d-flex  mb-3">
                    <span className="font-weight-bold text-dark ">
                      {localStorage.getItem("User")}
                    </span>
                  </h4>
                  <p className="tx-black-5 mb-1">
                    Athletics Federation of India
                  </p>
                </Col>
                <img
                  src={require("../../../assets/images/athlete.png")}
                  alt="athlete"
                />
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <!--Row--> */}
      <Row className="row-sm mx-0 mt-3">
        <Col lg={12}>
          <Card className=" custom-card overflow-hidden">
            <Card.Header className=" border-bottom-0">
              <label className="main-content-label my-auto text-danger">
                Meet Lists
              </label>
            </Card.Header>
            <Card.Body>
              <div className="responsive">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                  }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

DistrictDashboard.propTypes = {};

DistrictDashboard.defaultProps = {};

export default DistrictDashboard;
