import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  generatebulkstatelist,
  generatemeetlist,
  generatestatecertificate,
  generatestatelist,
} from "../../../services/superadmin";
import moment from "moment";
import { statelist } from "../../../services/registerservice";
import { toast } from "react-toastify";
const StateCertificate = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const gridRef = useRef();
  const { id } = useParams();
  const { type } = useParams();
  const [mname, setMeetName] = useState("");
  const [svalue, setState] = useState("");
  const [slist, setStatelist] = useState([]);
  useEffect(() => {
    statelist().then((response) => {
      if (response.status === true) {
        setStatelist(response.data);
      }
    });

    loadlist();
  }, []);

  function loadlist() {
    generatestatelist(id, type).then((response) => {
      if (response.status === true) {
        if(response.data.length > 0){
          setMeetName(response.data[0].meet_name);
          let arr=[];
          for(let i=0;i<response.data.length;i++){
            let event="-"
            if(response.data[i].meet_uid != 199){
              event=response.data[i].eventname
            }
            arr.push({
              age_category: response.data[i].age_category,
              certificate_name: response.data[i].certificate_name,
              certificate_type: response.data[i].certificate_type,
              certificate_uid: response.data[i].certificate_uid,
              district_name: response.data[i].district_name,
              dob: response.data[i].dob,
              eventname: event,
              fullname: response.data[i].fullname,
              meet_end_date: response.data[i].meet_end_date,
              meet_location: response.data[i].meet_location,
              meet_name: response.data[i].meet_name,
              meet_start_date: response.data[i].meet_start_date,
              meet_type: response.data[i].meet_type,
              meet_uid: response.data[i].meet_uid,
              state_name: response.data[i].state_name,
              user_uid: response.data[i].user_uid,
              username: response.data[i].username,
            })
          }
          setFilterProduct(arr);

        }
        
      }
    });
  }
  const columnDefs = [
    {
      headerName: "UID",
      width: 200,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 200,
      field: "fullname",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOB",
      width: 150,
      field: "dob",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      // cellRenderer: (data) => {
      //   return moment(data.value).format("DD-MM-YYYY");
      // },
    },
    {
      headerName: "Age Category",
      width: 150,
      field: "age_category",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event",
      width: 200,
      field: "eventname",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "District",
      width: 150,
      field: "district_name",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    
    {
      headerName: "Generated Date",
      width: 150,
      field: "created_at",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      // cellRenderer: (data) => {
      //   return moment(data.value).format("DD-MM-YYYY");
      // },
    },
    {
      headerName: "Actions",
      width: 100,
      field: "certificate_uid",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        if (params.data.certificate_name === null) {
          return (
            <div className="text-center">
              <a
                className="btn-sm btn-success"
                onClick={() =>
                  certcheck(params.data.certificate_uid, "generate")
                }
              >
                <i className="fa fa-plus fa-lg"></i>
              </a>
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <a
                className="btn-sm btn-info"
                onClick={() =>
                  certcheck(params.data.certificate_uid, "download")
                }
              >
                <i className="fa fa-download fa-lg"></i>
              </a>
            </div>
          );
        }
      },
    },
  ];

  function certcheck(uid, utype) {
    generatestatecertificate(id, uid, utype).then((response) => {
      if (response.status === true) {
        if (utype === "generate") {
          window.open(response.data, "_blank");
          loadlist();
        } else {
          let a = document.createElement("a");
          a.setAttribute("target", "_blank");
          a.setAttribute("href", response.data);
          a.download = response.data;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
    });
  }

  function gencert() {
    if (svalue !== "") {
      setLoading(true);

      generatebulkstatelist(id, svalue, type).then((response) => {
        if (response.status === true) {
          setLoading(false);
          window.open(response.data, "_blank");
          loadlist();
        }
      });
    } else {
      toast.error("Please Select Your State");
    }
  }
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  return (
    <Fragment>
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
          <h3>Loading...</h3>
        </div>
      )}
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Generate Certificate
          </h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="text-center w-100 mb-4">
                  <h5>Meet Name - {mname}</h5>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>State</label>
                      <select
                        value={svalue}
                        onChange={(e) => setState(e.target.value)}
                      >
                        <option value="" disabled>
                          Select Your State
                        </option>
                        <option value="All States">All States</option>
                        {slist.map((data, i) => {
                          return (
                            <option value={data.statename} key={i}>
                              {data.statename}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <a className="btn btn-success mt-4" onClick={gencert}>
                      Generate
                    </a>
                  </div>
                  <div className="col-md-3 text-right">
                    <a
                      className="btn btn-info mt-4"
                      onClick={() =>
                        navigate("/superadmin/generatecertificate")
                      }
                    >
                      Back
                    </a>
                  </div>
                </div>
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default StateCertificate;
