import React, { useState, useEffect, Fragment } from "react";
import { Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import {
  statefedmanagerdetail,
  statefedmanagerform,
} from "../../../services/state";
import { toast } from "react-toastify";
import PayUrl from "../../../Apiccavenue";
import { useNavigate, useParams } from "react-router-dom";
const ManagerForm = () => {
  const navigate = useNavigate();
  const {id}=useParams();
  const [mname, setMName] = useState("");
  const [fname, setFName] = useState("");
  const [eduqual, setEduQual] = useState("");
  const [techexam, setTechExam] = useState("");
  const [area, setArea] = useState("");
  const [email, setEmail] = useState("");
  const [mob, setMob] = useState("");
  const [addr, setAddr] = useState("");
  const [stat, setStat] = useState("");

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/logo/logo-small.png");
    ev.target.onerror = null;
  }
  const [urllink, setUrlLink] = useState("");
  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    setUrlLink(link[1])
    // window.location.replace(`${head[0]}/#/state/dashboard`);
    if(link[1] === "editmanagerform"){
      statefedmanagerdetail(id).then((res) => {
        if (res.status === true) {
          if (res.data.info !== null) {
            setMName(res.data.info.full_name);
            setFName(res.data.info.father_name);
            setEduQual(res.data.info.education_qualification);
            setTechExam(res.data.info.technical_examinationa_passed);
            setEmail(res.data.info.email_id);
            setMob(res.data.info.mobile_no);
            setArea(res.data.info.area_of_function);
            setAddr(res.data.info.address);
            setStat(res.data.info.payment_status);
            if (res.data.info.photograph !== null) {
              setPhotoFile(res.data.path + res.data.info.photograph);
            }
          }
        }
      });
    }
    
  }, []);

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }
  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMob(e.target.value);
    }
  };

  const [formphoto, setPhotoFile] = useState("");
  const [formphotoerror, setPhotoErrors] = useState("");
  const [formphotoupload, setPhotoUpload] = useState("");

  function photofile(event) {
    setPhotoErrors("");
    setPhotoFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoFile(URL.createObjectURL(event.target.files[0]));
        setPhotoUpload(event.target.files[0]);
      } else {
        setPhotoErrors("Photograph should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setPhotoErrors("Photograph size should be less than 5MB");
      }
    }
  }

  const [merror, setMError] = useState(false);
  const [emailerror, setEmailError] = useState("");
  const [moberror, setMobError] = useState("");

  function regsubmit(e) {
    e.preventDefault();
    setMError(true);
    setEmailError("");
    setMobError("");
    var chkerr = "";
    let valid = "";
    if (formphoto === "") {
      chkerr = "Photograph is required";
      setPhotoErrors("Photograph is required");
    }
    if (email !== "") {
      let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!regEmail.test(email)) {
        valid = "Please Enter a valid Email";
        setEmailError("Please Enter a valid Email");
      }
    }
    if (mob !== "") {
      if (mob.length < 10) {
        valid = "Mobile must be 10 characters";
        setMobError("Mobile must be 10 characters");
      }
    }

    if (
      chkerr === "" &&
      valid === "" &&
      mname !== "" &&
      fname !== "" &&
      eduqual !== "" &&
      techexam !== "" &&
      area !== "" &&
      email !== "" &&
      mob !== "" &&
      addr !== "" &&
      formphoto !== "" &&
      formphotoerror === ""
    ) {
      const temp = new FormData();
      temp.append("full_name", mname);
      temp.append("father_name", fname);
      temp.append("education_qualification", eduqual);
      temp.append("technical_examinationa_passed", techexam);
      temp.append("area_of_function", area);
      temp.append("email_id", email);
      temp.append("mobile_no", mob);
      temp.append("address", addr);
      temp.append("photograph", formphotoupload);
      statefedmanagerform(temp).then((res) => {
        if (res.status === true) {
          setMError(false);
          window.open(
            PayUrl + "state-meet-manager-billing/" + res.data.info.unique_no,
            "_self"
          );
          // toast.success(res.message);
        }
      });
      //
    }
  }
  return (
    <Fragment>
      <div id="statedash">
        <div className="page-header reported-header">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">
              Meet Manager Course Form
            </h2>
          </div>
        </div>
        {(urllink === "managerform" || (stat !== "SUCCESS" && urllink === "editmanagerform")) && (
          <Row className="row-sm mx-0">
            <Col xxl={12} lg={12} md={12}>
              <Card className="custom-card overflow-hidden eventslist">
                <Card.Body>
                  <form>
                    <Row className="row-sm mx-0">
                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            Name : <b className="text-danger">*</b>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Name"
                            onChange={(e) => setMName(e.target.value)}
                            value={mname}
                            onKeyPress={LetterOnly}
                            onPaste={onPasteLetter}
                          />
                          {merror && !mname && (
                            <span className="errortxt text-danger">
                              Name is required
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            Father Name : <b className="text-danger">*</b>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Father Name"
                            onChange={(e) => setFName(e.target.value)}
                            value={fname}
                            onKeyPress={LetterOnly}
                            onPaste={onPasteLetter}
                          />
                          {merror && !fname && (
                            <span className="errortxt text-danger">
                              Father Name is required
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            Educational Qualification :{" "}
                            <b className="text-danger">*</b>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Educational Qualification"
                            onChange={(e) => setEduQual(e.target.value)}
                            value={eduqual}
                          />
                          {merror && !eduqual && (
                            <span className="errortxt text-danger">
                              Educational Qualification is required
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            Technical Examination Passed :{" "}
                            <b className="text-danger">*</b>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Technical Examination Passed"
                            onChange={(e) => setTechExam(e.target.value)}
                            value={techexam}
                          />
                          {merror && !techexam && (
                            <span className="errortxt text-danger">
                              Technical Examination Passed is required
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            Area of Function in Athletics :{" "}
                            <b className="text-danger">*</b>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Area of Function in Athletics"
                            onChange={(e) => setArea(e.target.value)}
                            value={area}
                          />
                          {merror && !area && (
                            <span className="errortxt text-danger">
                              Area of Function in Athletics is required
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            Email ID : <b className="text-danger">*</b>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Email ID"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                          {merror && !email && (
                            <span className="errortxt text-danger">
                              Email ID is required
                            </span>
                          )}
                          {emailerror !== "" && email && (
                            <span className="errortxt text-danger">
                              {emailerror}
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            Mobile Number : <b className="text-danger">*</b>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Mobile Number"
                            value={mob}
                            onChange={handleChange}
                            maxLength={10}
                          />
                          {merror && !mob && (
                            <span className="errortxt text-danger">
                              Mobile Number is required
                            </span>
                          )}
                          {moberror !== "" && mob && (
                            <span className="errortxt text-danger">
                              {moberror}
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            Address : <b className="text-danger">*</b>
                          </label>
                          <textarea
                            rows="2"
                            placeholder="Enter Address"
                            onChange={(e) => setAddr(e.target.value)}
                            value={addr}
                          />
                          {merror && !addr && (
                            <span className="errortxt text-danger">
                              Address is required
                            </span>
                          )}
                        </div>
                      </Col>

                      <div className="input-group form-upload">
                        <div className="row">
                          <div className="col-md-9">
                            <label>
                              Photo of Applicant (Passport Size):{" "}
                              <b className="text-danger">*</b>
                            </label>
                            <span className="desc text-danger">
                              ( Files Should be .png, .jpg, .jpeg )
                            </span>
                            <div className="mt-3">
                              <input
                                type="file"
                                onChange={photofile}
                                accept=".png,.jpg,.jpeg"
                              />
                            </div>
                          </div>
                          {formphoto !== "" && (
                            <div className="col-md-3">
                              <div className="imagecontainer">
                                <img
                                  src={formphoto}
                                  width="100%"
                                  height="auto"
                                />
                              </div>
                            </div>
                          )}

                          {formphoto === "" && (
                            <div className="col-md-3">
                              <div className="imagecontainer">
                                <img
                                  src={require("../../../assets/images/placeholder.png")}
                                  width="100%"
                                  height="auto"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        {formphotoerror !== "" && (
                          <span className="errortxt text-danger">
                            {formphotoerror}
                          </span>
                        )}
                      </div>
                    </Row>
                    <div className="mt-3">
                      <a
                        className="btn btn-success mr-3"
                        style={{ float: "right" }}
                        onClick={regsubmit}
                      >
                        Submit
                      </a>
                      <a
                        className="btn btn-secondary me-2"
                        style={{ float: "right" }}
                        onClick={() => navigate("/state/managerlist/")}
                      >
                        Back
                      </a>
                    </div>
                  </form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        {stat === "SUCCESS" && urllink === "editmanagerform" && (
          <Row className="row-sm mx-0">
            <Col xxl={12} lg={12} md={12}>
              <Card className="custom-card overflow-hidden eventslist">
                <Card.Body>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Name</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{mname}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Father Name</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{fname}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">
                              Educational Qualification
                            </b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{eduqual}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">
                              Technical Examination Passed
                            </b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{techexam}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">
                              Area of Function in Athletics
                            </b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{area}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Email ID</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{email}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Mobile Number</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{mob}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Address</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{addr}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Photo of Applicant</b>
                          </div>
                          <div className="col-md-8">
                            <img
                              src={formphoto}
                              alt={mname}
                              height={"200px"}
                              width={"200px"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <a
                      className="btn btn-secondary mr-3"
                      style={{ float: "right" }}
                      onClick={() => navigate("/state/managerlist/")}
                    >
                      Back
                    </a>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </Fragment>
  );
};

export default ManagerForm;
