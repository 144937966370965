import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
const RegisterSuccess = () => {
  const navigate = useNavigate();
  const [urllink, setUrllink] = useState("");

  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    setUrllink(head[1]);
  }, [window.location.href]);

  return (
    <Fragment>
      {/* <Card className=" custom-card overflow-hidden">
        <Card.Body>
          <div className="text-right">
            <Link className="btn btn-secondary mr-2" to={"/login"}>
              Back
            </Link>
          </div>
          <Row className="mx-0">
            <Col md={12} className="px-0">
              <Card className="pt-0  custom-card pt-2 bg-background2 card pb-2 border-0 overflow-hidden text-center">
                <div className="header-text mb-0">
                  <Container fluid className="p-3 text-center">
                    <div className="text-center text-white background-text ">
                      <h1 className="mb-3 tx-46 font-weight-semibold text-primary">
                        Registered successfully !
                      </h1>
                      <p className="tx-20 mb-3 text-success">
                        You have successfully submitted the application for
                        AFI-UID. <br></br>Upon AMIN approval, you will be
                        receiving your AFI-UID within next 7 days.
                      </p>
                    </div>
                  </Container>
                </div>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card> */}
      <div id="registrationpage">
        <div className="registrationmain">
          <div className="registrationleft">
            <img
              src={require("../../assets/images/logo/logo.png")}
              height="60"
              alt="AFI"
              onClick={() => {
                navigate("/login");
              }}
            />
          </div>

          <div className="registrationright">
            <form action="#" className="form">
              <div className="text-right">
                <Link className="btn btn-secondary mr-2" to={"/login"}>
                  Back
                </Link>
              </div>

              <Container fluid className="p-3 text-center mt-5">
                <div className="text-center text-white background-text ">
                  <h1 className="mb-3 tx-46 font-weight-semibold text-primary">
                    Registration successful !
                  </h1>
                  {urllink === "success" && (
                    <p className="tx-20 mb-3 text-success">
                      You have successfully submitted the application for
                      AFI-UID. <br></br>Upon ADMIN approval, you will be
                      receiving your AFI-UID within next 7 days.
                    </p>
                  )}
                  {urllink === "coachsuccess" && (
                    <p className="tx-20 mb-3 text-success">
                      You have successfully submitted the application for AFI
                      Coaches ID. <br></br>Upon ADMIN approval, you will be
                      receiving your AFI Coaches ID within next 7 days.
                    </p>
                  )}
                </div>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RegisterSuccess;
