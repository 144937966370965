import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card } from "react-bootstrap";
import { Row, Col, Modal } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  athletecertificatedownload,
  athletecertificatelist,
} from "../../../services/athlete";
const Certificatelist = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const gridRef = useRef();
  useEffect(() => {
    athletecertificatelist().then((response) => {
      if (response.status === true) {
        let arr=[];
        if(response.data.length>0){
          for(let i=0;i<response.data.length;i++){
            let event="-"
            if(response.data[i].meet_uid != 199){
              event=response.data[i].event_name
            }
            arr.push({
              meet_name: response.data[i].meet_name,
              meet_location: response.data[i].meet_location,
              meet_start_date: response.data[i].meet_start_date,
              meet_end_date: response.data[i].meet_end_date,
              event_name: event,
              state_name: response.data[i].state_name,
              meet_uid: response.data[i].meet_uid,
              certificate_name: response.data[i].certificate_name,
              circular: response.data[i].circular,
              type: response.data[i].type
            })
          }
        }
        setFilterProduct(arr);
      }
    });
  }, []);

  const columnDefs = [
    {
      headerName: "Meet",
      width: 400,
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Venue",
      width: 200,
      field: "meet_location",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Start Date",
      width: 150,
      field: "meet_start_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "End Date",
      width: 150,
      field: "meet_end_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Event",
      width: 150,
      field: "event_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
     
    },
    {
      headerName: "State/District",
      width: 150,
      field: "state_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Circular",
    //   width: 150,
    //   field: "circular",
    //   sortable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Type",
      width: 120,
      field: "type",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value === null) {
          return "P";
        } else {
          return data.value;
        }
      },
    },
    {
      headerName: "Actions",
      width: 100,
      field: "meet_uid",
      filter: false,
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="btn-sm btn-info"
              onClick={() =>
                view(
                  params.data.meet_uid,
                  localStorage.getItem("Userid"),
                  params.data.certificate_name,
                  params.data.type
                )
              }
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];
  const [file, setFile] = useState("");
  const [show, setShow] = useState(false);

  function handleclose() {
    setShow(false);
  }
  const navigate = useNavigate();
  function view(tid, uid, cname,ctype) {
    athletecertificatedownload(tid, uid, cname,ctype).then((response) => {
      if (response.status === true) {
        setFile("");
        setFile(response.data);
        setShow(true);
      }
    });
  }
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Certificate List</h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
            <Modal show={show}>
              <Modal.Body>
                <img src={file} alt="Certificate" />
              </Modal.Body>
              <Modal.Footer>
                <a
                  className="btn btn-next btn-success"
                  style={{ float: "right" }}
                  href={file}
                  download
                  target="_blank"
                >
                  Download
                </a>
                <a
                  className="btn btn-next btn-danger"
                  style={{ float: "right" }}
                  onClick={handleclose}
                >
                  Close
                </a>
              </Modal.Footer>
            </Modal>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Certificatelist;
