import React, { useState } from "react";
import { Fragment } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Managerfailure = () => {
  useState(() => {}, []);

  return (
    <Fragment>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Manager Course Registration Failed
          </h2>
        </div>
      </div>
      <Row className="mx-0">
        <Col md={12} className="px-0">
          <Card className="pt-0  custom-card pt-2 bg-background2 card pb-2 border-0 overflow-hidden text-center">
            <div className="header-text mb-0">
              <div className="text-right">
                <Link
                  className="btn btn-secondary mr-2 me-2 mt-2"
                  to={"/state/managerlist"}
                >
                  Back
                </Link>
              </div>
              <Container fluid className="p-3 text-center">
                <div className="text-center text-white background-text ">
                  <h1 className="mb-3 tx-46 font-weight-semibold text-primary">
                    Manager Course Registration Failed !
                  </h1>
                  <p className="tx-20 mb-3 text-success">
                    Your payment for Manager Course Registration has been failed{" "}
                    <br></br>Please try again.
                  </p>
                </div>
              </Container>
            </div>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Managerfailure;
