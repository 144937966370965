import React from "react";
import { Navigate } from "react-router-dom";
import Password from "../components/forgot/password";
import Login from "../components/login/login";
import store from "../components/login/store";
import athstore from "../components/registration/store";
import forgotstore from "../components/forgot/store";
import Athleteidcard from "../components/registration/athleteidcard";
import Athleteregister from "../components/registration/athleteregister";
import Addathlete from "../components/superadmin/athletes/addathlete";
import Payment from "../components/registration/payment";
import RejectEditAthlete from "../components/superadmin/athletes/rejectedview";
import RegisterSuccess from "../components/registration/success";
import RegisterFailure from "../components/registration/failure";
import CertificateQR from "../components/superadmin/certificate/certificateqr";
import StateCertificateQR from "../components/superadmin/certificate/certificateqrstate";
import TourRegister from "../components/tournamentregister/register";
import Coachregister from "../components/registration/coachregister";
export const routes = [
  {
    id: 1,
    path: "/",
    exact: false,
    element: <Navigate to="/login" />,
    provider: store,
  },
  {
    id: 2,
    path: "/login",
    exact: true,
    element: <Login />,
    provider: store,
  },
  {
    id: 3,
    path: "/register",
    exact: false,
    element: <Athleteregister />,
    provider: athstore,
  },
  {
    id: 4,
    path: "/coachregister",
    exact: false,
    element: <Coachregister />,
    provider: athstore,
  },
  {
    id: 5,
    path: "/forgotpassword",
    exact: true,
    element: <Password />,
    provider: forgotstore,
  },
  {
    id: 6,
    path: "/forgotusername",
    exact: true,
    element: <Password />,
    provider: forgotstore,
  },
  {
    id: 7,
    path: "/payment",
    exact: true,
    element: <Payment />,
    provider: forgotstore,
  },
  {
    id: 8,
    path: "/rejecteditathlete/:id",
    exact: false,
    element: <RejectEditAthlete />,
    provider: athstore,
  },
  {
    id: 9,
    path: "/success",
    exact: false,
    element: <RegisterSuccess />,
    provider: forgotstore,
  },
  {
    id: 10,
    path: "/failure",
    exact: false,
    element: <RegisterFailure />,
    provider: forgotstore,
  },
  {
    id: 11,
    path: "/certificatedownload/:id",
    exact: false,
    element: <CertificateQR />,
    provider: forgotstore,
  },
  {
    id: 12,
    path: "/state/certificatedownload/:id",
    exact: false,
    element: <StateCertificateQR />,
    provider: forgotstore,
  },
  {
    id: 13,
    path: "/athleteidcard",
    exact: false,
    element: <Athleteidcard />,
    provider: athstore,
  },
  {
    id: 14,
    path: "/rejecteditcoach/:id",
    exact: false,
    element: <RejectEditAthlete />,
    provider: athstore,
  },
  {
    id: 9,
    path: "/coachsuccess",
    exact: false,
    element: <RegisterSuccess />,
    provider: forgotstore,
  },
  
];
export default {
  routes,
};
