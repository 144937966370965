import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { athletereg } from "./action";
import dataString from "../../assets/language/env.json";
import LocalizedStrings from "react-localization";
import "./registration.css";
import {
  bloodlist,
  distlist,
  registerdata,
  statelist,
  validatemail,
  validatemobile,
} from "../../services/registerservice";
import { Col, Modal, Row } from "react-bootstrap";
import moment from "moment/moment";
import payString from "../../assets/language/ccavenue.json";
import PayUrl from "../../Apiccavenue";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

let strings = new LocalizedStrings(dataString);

function Athleteregister() {
  const [dateday, setDaylist] = useState([]);
  const [dateyear, setYearlist] = useState([]);
  const [lstep, setStep] = useState(1);
  const [step1submit, setStep1] = useState(false);
  const [step2submit, setStep2] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [emailerror, setEmailError] = useState("");
  const [reemailerror, setReEmailError] = useState("");
  const [moberror, setMobError] = useState("");
  const [remoberror, setReMobError] = useState("");
  const [aaderror, setAadError] = useState("");

  const [fname, setFName] = useState("");
  const [mname, setMName] = useState("");
  const [lname, setLName] = useState("");
  const [dob, setDOB] = useState("");
  const [daygroup, setDateDay] = useState("");
  const [monthgroup, setDateMonth] = useState("");
  const [yeargroup, setDateYear] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [reemail, setReEmail] = useState("");
  const [blood, setBlood] = useState("");
  const [mob, setMob] = useState("");
  const [remob, setReMob] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [father, setFathar] = useState("");
  const [mother, setMothar] = useState("");

  const [check, setCheck] = useState("");
  const [padd1, setPAdd1] = useState("");
  const [padd2, setPAdd2] = useState("");
  const [pdist, setPDist] = useState("");
  const [ppin, setPPin] = useState("");
  const [pstate, setPState] = useState("");
  const [cadd1, setCAdd1] = useState("");
  const [cadd2, setCAdd2] = useState("");
  const [cdist, setCDist] = useState("");
  const [cpin, setCPin] = useState("");
  const [cstate, setCState] = useState("");

  const [lang, setLang] = useState("en");

  function langChange(e) {
    e.preventDefault();
    setLang(e.target.value);
    strings.setLanguage(e.target.value);
  }
  function datelist() {
    var day = [];

    for (let i = 1; i <= 31; i++) {
      day.push(i);
    }
    setDaylist(day);

    var year = [];

    for (let i = 1930; i <= new Date().getFullYear() - 5; i++) {
      year.push(i);
    }
    setYearlist(year);
  }

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMob(e.target.value);
    }
  };

  const ReMobChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setReMob(e.target.value);
    }
  };

  const AadharChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setAadhar(e.target.value);
    }
  };

  const PerPinchange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setPPin(e.target.value);
    }
  };

  const ComPinchange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setCPin(e.target.value);
    }
  };

  const [blist, setBloodlist] = useState([]);
  const [slist, setStatelist] = useState([]);
  const [pdlist, setPDList] = useState([]);
  const [cdlist, setCDList] = useState([]);

  function registerlist() {
    bloodlist().then((response) => {
      if (response.status === true) {
        setBloodlist(response.data);
      }
    });
    statelist().then((response) => {
      if (response.status === true) {
        setStatelist(response.data);
      }
    });
  }

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function LastNameOnly(eve) {
    if (eve.key !== ".") {
      if (/^[a-zA-Z\s]*$/.test(eve.key)) {
        return true;
      } else {
        eve.preventDefault();
        return false;
      }
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  function Next1step() {
    if (lstep === 1) {
      setStep1(true);
      setEmailError("");
      setReEmailError("");
      setMobError("");
      setReMobError("");
      setAadError("");
      var valid = "";
      if (daygroup !== "" && monthgroup !== "" && yeargroup !== "") {
        // setDOB(daygroup + '/'+ monthgroup + '/' + yeargroup);
        setDOB(yeargroup + "-" + monthgroup + "-" + daygroup);
      }
      let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!regEmail.test(email)) {
        valid = "Please Enter a valid Email";
        setEmailError("Please Enter a valid Email");
      }
      if (!regEmail.test(reemail)) {
        valid = "Please Enter a valid Email";
        setReEmailError("Please Enter a valid Email");
      } else if (email !== "" && reemail !== "" && email !== reemail) {
        valid = "Email didn't match";
        setReEmailError("Email didn't match");
      }

      if (mob.length < 10) {
        valid = "Mobile must be 10 characters";
        setMobError("Mobile must be 10 characters");
      }

      if (remob.length < 10) {
        valid = "Re Enter Mobile must be 10 characters";
        setReMobError("Re Enter Mobile must be 10 characters");
      } else if (mob !== "" && remob !== "" && mob !== remob) {
        valid = "Mobile didn't match";
        setReMobError("Mobile didn't match");
      }

      if (aadhar !== "" && aadhar.length < 12) {
        valid = "Aadhar Number must be 12 characters";
        setAadError("Aadhar Number must be 12 characters");
      }
      if (
        fname !== "" &&
        lname !== "" &&
        daygroup !== "" &&
        monthgroup !== "" &&
        yeargroup !== "" &&
        gender !== "" &&
        email !== "" &&
        valemail === "" &&
        reemail !== "" &&
        valremail === "" &&
        blood !== "" &&
        mob !== "" &&
        valmob === "" &&
        remob !== "" &&
        valremob === "" &&
        valid === "" &&
        aaderror === "" &&
        father !== ""
      ) {
        setStep(2);
      }
    }

    if (lstep === 2) {
      setStep2(true);
      if (
        padd1 !== "" &&
        pdist !== "" &&
        ppin !== "" &&
        ppin.length === 6 &&
        pstate !== "" &&
        cadd1 !== "" &&
        cdist !== "" &&
        cpin !== "" &&
        cstate !== "" &&
        cpin.length === 6
      ) {
        setStep(3);
      }
    }

    if (lstep === 3) {
      if (formphoto === "" && formdob === "" && formaddr === "") {
        setPhotoErrors("Photograph is required");
        setDobErrors("DOB Proof is required");
        setAddrErrors("Address Proof is required");
      }
      if (formphoto === "") {
        setPhotoErrors("Photograph is required");
      } else if (formdob === "") {
        setDobErrors("DOB Proof is required");
      } else if (formaddr === "") {
        setAddrErrors("Address Proof is required");
      } else if (
        formphotoerror === "" &&
        formaddrerror === "" &&
        formdoberror === "" &&
        formphoto !== "" &&
        formaddr !== "" &&
        formdob !== ""
      ) {
        setStep(4);
      }
    }
  }

  function checkcomm(e) {
    setCheck(e.target.checked);
    setCAdd1("");
    setCAdd2("");
    setCDist("");
    setCPin("");
    setCState("");
    if (e.target.checked === true) {
      setCAdd1(padd1);
      setCAdd2(padd2);
      setCDist(pdist);
      setCPin(ppin);
      setCState(pstate);
      setCDList([]);
      distlist(pstate).then((response) => {
        setCDList(response.data);
      });
    } else {
      setCAdd1("");
      setCAdd2("");
      setCDist("");
      setCPin("");
      setCState("");
    }
  }

  const [sname, setStateName] = useState("");
  const [scode, setStateCode] = useState("");

  ///Permanent District
  function Selectpdist(e) {
    setPState(e.target.value);
    setPDist("");
    setStateName("");
    setStateCode("");
    if (e.target.value !== "") {
      statelist().then((response) => {
        if (response.status === true) {
          for (let i = 0; i < response.data.length; i++) {
            if (e.target.value === response.data[i].stateuid + "") {
              setStateCode(response.data[i].statecode);
              setStateName(response.data[i].statename);
            }
          }
        }
      });
      distlist(e.target.value).then((response) => {
        setPDList(response.data);
      });
    }
  }

  ///Communication District
  function Selectcdist(e) {
    setCState(e.target.value);
    setCDist("");
    distlist(e.target.value).then((response) => {
      setCDList(response.data);
    });
  }
  // Photograph
  const [formphotoerror, setPhotoErrors] = useState("");
  const [formphoto, setPhotoFile] = useState("");
  const [formphotoupload, setPhotoUpload] = useState("");

  const [cshow, setCShow] = useState(false);
  function setIShow() {
    setCShow(true);
  }
  const setIClose = () => setCShow(false);

  const cropperRef = useRef(null);
  const [croppedImg, setCroppedImg] = useState("");
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedImg(cropper.getCroppedCanvas().toDataURL());

    const base64 = cropper.getCroppedCanvas().toDataURL();

    const current = new Date();
    const timestamp = current.getTime();
    const imageName = fname + timestamp + ".png";
    const arr = base64.split(",");
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    // setCroppedImg(new File([u8arr], imageName, { type: 'image/png' }));
    var pfile = new File([u8arr], imageName, { type: "image/png" });
    setPhotoUpload(new File([u8arr], imageName, { type: "image/png" }));

    var size = pfile.size / 1024 / 1024;
    if (size > 5) {
      setPhotoErrors("Photograph size should be less than 5MB");
    }
  };
  function photofile(event) {
    setPhotoErrors("");
    setPhotoFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoFile(URL.createObjectURL(event.target.files[0]));
        // setPhotoUpload(event.target.files[0]);
        setIShow();
      } else {
        setPhotoErrors("Photograph should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setPhotoErrors("Photograph size should be less than 5MB");
      }
    }
  }

  // DOB Proof
  const [formdoberror, setDobErrors] = useState("");
  const [formdob, setDobFile] = useState("");
  const [formdobupload, setDobUpload] = useState("");

  function dobfile(event) {
    setDobErrors("");
    setDobFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setDobFile(URL.createObjectURL(event.target.files[0]));
        setDobUpload(event.target.files[0]);
      } else {
        setDobErrors("DOB Proof should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setDobErrors("DOB Proof size should be less than 5MB");
      }
    }
  }

  // Address
  const [formaddrerror, setAddrErrors] = useState("");
  const [formaddr, setAddrFile] = useState("");
  const [formaddrupload, setAddrUpload] = useState("");

  function addrfile(event) {
    setAddrErrors("");
    setAddrFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setAddrFile(URL.createObjectURL(event.target.files[0]));
        setAddrUpload(event.target.files[0]);
      } else {
        setAddrErrors("Address Proof should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setAddrErrors("Address Proof size should be less than 5MB");
      }
    }
  }

  // BPL Status
  const [bplstatus, setBplStatus] = useState("N");
  function Selectbplstatus(event) {
    setBplStatus(event.target.value);
    if (event.target.value === "N") {
      setBPLErrors("");
      setBPLFile("");
    }
  }

  // BPL Card
  const [formbplerror, setBPLErrors] = useState("");
  const [formbpl, setBPLFile] = useState("");
  const [formbplupload, setBPLUpload] = useState("");

  function bplfile(event) {
    setBPLErrors("");
    setBPLFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setBPLFile(URL.createObjectURL(event.target.files[0]));
        setBPLUpload(event.target.files[0]);
      } else {
        setBPLErrors("BPL Card should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setBPLErrors("BPL Card size should be less than 5MB");
      }
    }
  }

  function previous(e) {
    e.preventDefault();
    setStep(1);
  }

  function previous2(e) {
    e.preventDefault();
    setStep(2);
  }

  function previous3(e) {
    e.preventDefault();
    setStep(3);
  }

  // Disclaimers
  const [term1checked, setTerm1] = useState(false);
  const checkterm1 = (e) => {
    setTerm1(e.target.checked);
  };

  const [term2checked, setTerm2] = useState(false);
  const checkterm2 = (e) => {
    setTerm2(e.target.checked);
  };

  const [term3checked, setTerm3] = useState(false);
  const checkterm3 = (e) => {
    setTerm3(e.target.checked);
  };

  const [term4checked, setTerm4] = useState(false);
  const checkterm4 = (e) => {
    setTerm4(e.target.checked);
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const [show, setShow] = useState(false);
  function handleShow() {
    setShow(true);
  }
  const handleClose = () => setShow(false);

  function Previewstep(e) {
    e.preventDefault();
    var chkerr = "";
    if (bplstatus === "Y") {
      if (formbpl === "") {
        chkerr = "BPL Card is required";
        setBPLErrors("BPL Card is required");
      }
    }
    if (
      term1checked === true &&
      term2checked === true &&
      term3checked === true
    ) {
      setDiscerror("");
      if (chkerr === "") {
        handleShow();
      }
    } else {
      setDiscerror("Please Select All Disclaimer");
    }
  }

  const [discerror, setDiscerror] = useState("");
  const [iderror, setIDerror] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  function regsubmit(e) {
    e.preventDefault();
    // if (bplstatus === "Y") {
    //   if (formbpl === "") {
    //     setBPLErrors("BPL Card is required");
    //   }
    // }
    var idchk = "";
    setIDerror("");
    if (term4checked === false) {
      idchk = "Please Tick the checkbox";
      setIDerror("Please Tick the checkbox");
    } else if (term4checked === true) {
      setIDerror("");
      if (idchk === "") {
        setLoading(true);

        dispatch(
          athletereg({
            first: fname,
            last: lname,
            date: dob,
            gen: gender,
            email: email,
            bgroup: blood,
            phone: mob,
            aadno: aadhar,
            faname: father,
            moname: mother,
            peradd1: padd1,
            peradd2: padd2,
            perst: pstate,
            perdt: pdist,
            perpin: ppin,
            comadd1: cadd1,
            comadd2: cadd2,
            comst: cstate,
            comdt: cdist,
            compin: cpin,
            photo: formphotoupload,
            dobphoto: formdobupload,
            addphoto: formaddrupload,
            bplst: bplstatus,
            bplphoto: formbplupload,
          })
        );

        const tempData = new FormData();
        tempData.append("first_name", fname);
        tempData.append("middle_name", "");
        tempData.append("last_name", lname);
        tempData.append("date_of_birth", dob);
        tempData.append("gender", gender);
        tempData.append("email_id", email);
        tempData.append("blood_group", blood);
        tempData.append("mobile_no", mob);
        tempData.append("aadhaar_no", aadhar);
        tempData.append("father_name", father);
        tempData.append("mother_name", mother);
        tempData.append("permenant_address_one", padd1);
        tempData.append("permenant_address_two", padd2);
        tempData.append("permenant_state", pstate);
        tempData.append("permenant_district", pdist);
        tempData.append("permenant_zipcode", ppin);
        tempData.append("communication_address_one", cadd1);
        tempData.append("communication_address_two", cadd2);
        tempData.append("communication_state", cstate);
        tempData.append("communication_district", cdist);
        tempData.append("communication_zipcode", cpin);
        tempData.append("photograph", formphotoupload);
        tempData.append("address_proof", formaddrupload);
        tempData.append("dob_proof", formdobupload);
        tempData.append("below_poverty_file", formbplupload);
        tempData.append("below_poverty_card", bplstatus);

        registerdata(tempData).then((response) => {
          if (response.status === true) {
            setLoading(false);
            window.open(
              PayUrl +
                payString.registerpayment +
                response.data.userdetails.unique_no,
              "_self"
            );
          } else {
            setLoading(false);
            if(response.message.length !== 0){
              setErrorMessage(response.message.join(','));

            }
          }
        });
      }
    }
  }

  const [valemail, setValEmail] = useState("");
  function checkemail(e) {
    e.preventDefault();
    setValEmail("");
    setEmailError("");
    if (e.target.value !== "") {
      validatemail(e.target.value,2).then((response) => {
        if (response.status === false) {
          setValEmail(response.message);
        }
      });
    }
  }

  const [valremail, setValReEmail] = useState("");
  function checkreemail(e) {
    e.preventDefault();
    setValReEmail("");
    setReEmailError("");
    if (e.target.value !== "") {
      validatemail(e.target.value,2).then((response) => {
        if (response.status === false) {
          setValReEmail(response.message);
        }
      });
    }
  }

  const [valmob, setValMob] = useState("");
  function checkmobile(e) {
    e.preventDefault();
    setValMob("");
    setMobError("");
    if (e.target.value !== "") {
      validatemobile(e.target.value,2).then((response) => {
        if (response.status === false) {
          setValMob(response.message);
        }
      });
    }
  }
  const [valremob, setValReMob] = useState("");
  function checkremobile(e) {
    e.preventDefault();
    setValReMob("");
    setReMobError("");

    if (e.target.value !== "") {
      validatemobile(e.target.value,2).then((response) => {
        if (response.status === false) {
          setValReMob(response.message);
        }
      });
    }
  }
  useEffect(() => {
    datelist();
    registerlist();
  }, []);

  const [loading, setLoading] = useState(false);

  return (
    <div id="registrationpage">
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}

      <div className="registrationmain">
        <div className="registrationleft">
          <img
            src={require("../../assets/images/logo/logo.png")}
            height="60"
            alt="AFI"
            onClick={() => {
              navigate("/login");
            }}
          />
        </div>

        <div className="registrationright mt-2">
          <form action="#" className="form">
            <div className="row">
              <div className="col-md-4 col-lg-4 col-sm-12">
                <select
                  className="selectBox"
                  name="lang"
                  onChange={langChange}
                  value={lang}
                >
                  <option value="en">English</option>
                  <option value="hi">Hindi (हिन्दी)</option>
                </select>
              </div>
              <div className="col-md-8 col-lg-8 col-sm-12">
                <h2 className="text-right">{strings.title}</h2>
              </div>
            </div>
            <div className="progressbar">
              <div
                className="progress"
                id="progress"
                style={{
                  width:
                    lstep === 1
                      ? "0%"
                      : lstep === 2
                      ? "33.3%"
                      : lstep === 3
                      ? "66.6%"
                      : "100%",
                }}
              ></div>

              <div
                className="progress-step progress-step-active"
                data-title="Information"
              ></div>
              <div
                className={`progress-step ${
                  lstep >= 2 ? "progress-step-active" : ""
                }`}
                data-title="Address"
              ></div>
              <div
                className={`progress-step ${
                  lstep >= 3 ? "progress-step-active" : ""
                }`}
                data-title="Files"
              ></div>
              <div
                className={`progress-step ${
                  lstep >= 4 ? "progress-step-active" : ""
                }`}
                data-title="Password"
              ></div>
            </div>

            {/* Step One */}
            {lstep === 1 && (
              <div className="form-step form-step-active">
                <div className="form-inner">
                  <h4>{strings.personal}</h4>
                  <div className="row">
                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          {strings.first} :<b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Your First Name"
                          onKeyPress={LetterOnly}
                          onPaste={onPasteLetter}
                          value={fname}
                          onChange={(e) => setFName(e.target.value)}
                          id="fname"
                          autoComplete="off"
                        />
                        {step1submit && !fname && (
                          <span className="errortxt text-danger">
                            First Name is required
                          </span>
                        )}
                      </div>
                    </div>
                  
                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          {strings.last} : <b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Your Last/Family Name"
                          value={lname}
                          onChange={(e) => setLName(e.target.value)}
                          onKeyPress={LastNameOnly}
                          onPaste={onPasteLetter}
                          id="lasr"
                          autoComplete="off"
                        />
                        {step1submit && !lname && (
                          <span className="errortxt text-danger">
                            Last/Family Name is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          {strings.dbirth} :<b className="text-danger">*</b>
                        </label>
                        <div className="row">
                          <div className="col-md-4 col-sm-4 date-field">
                            <select
                              value={daygroup}
                              onChange={(e) => {
                                setDateDay(e.target.value);
                              }}
                              id="day"
                              autoComplete="off"
                            >
                              <option value="" disabled>
                                Date
                              </option>
                              {dateday.map((data, i) => {
                                return (
                                  <option value={data} key={i}>
                                    {data}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="col-md-4 col-sm-4 date-field">
                            <select
                              value={monthgroup}
                              onChange={(e) => {
                                setDateMonth(e.target.value);
                              }}
                              id="month"
                            >
                              <option value="" disabled>
                                Month
                              </option>
                              <option value="01">January</option>
                              <option value="02">February</option>
                              <option value="03">March</option>
                              <option value="04">April</option>
                              <option value="05">May</option>
                              <option value="06">June</option>
                              <option value="07">July</option>
                              <option value="08">August</option>
                              <option value="09">September</option>
                              <option value="10">October</option>
                              <option value="11">November</option>
                              <option value="12">December</option>
                            </select>
                          </div>
                          <div className="col-md-4 col-sm-4 date-field">
                            <select
                              value={yeargroup}
                              onChange={(e) => {
                                setDateYear(e.target.value);
                              }}
                              id="year"
                            >
                              <option value="" disabled>
                                Year
                              </option>
                              {dateyear.map((data, i) => {
                                return (
                                  <option value={data} key={i}>
                                    {data}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        {step1submit && !dob && (
                          <span className="errortxt text-danger">
                            Date of Birth is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          {strings.gen} :<b className="text-danger">*</b>
                        </label>
                        <select
                          value={gender}
                          onChange={(e) => {
                            setGender(e.target.value);
                          }}
                          id="gender"
                        >
                          <option value="" disabled>
                            Select Your Gender
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                        {step1submit && !gender && (
                          <span className="errortxt text-danger">
                            Gender is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          {strings.email} :<b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Your Email"
                          onBlur={checkemail}
                          id="email"
                          autoComplete="off"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                        {step1submit && !email && (
                          <span className="errortxt text-danger">
                            Email is required
                          </span>
                        )}
                        {emailerror !== "" && email && (
                          <span className="errortxt text-danger">
                            {emailerror}
                          </span>
                        )}
                        {email && valemail !== "" && emailerror === "" && (
                          <span className="errortxt text-danger">
                            {valemail}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          {strings.remail} :<b className="text-danger">*</b>{" "}
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Your Re Enter Email"
                          onBlur={checkreemail}
                          autoComplete="off"
                          id="remail"
                          value={reemail}
                          onChange={(e) => {
                            setReEmail(e.target.value);
                          }}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                        {step1submit && !reemail && (
                          <span className="errortxt text-danger">
                            Re Enter Email is required
                          </span>
                        )}
                        {reemailerror !== "" && reemail && (
                          <span className="errortxt text-danger">
                            {reemailerror}
                          </span>
                        )}
                        {valremail !== "" && reemail && reemailerror === "" && (
                          <span className="errortxt text-danger">
                            {valremail}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          {strings.mobile} :<b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Your Mobile"
                          maxLength={10}
                          onBlur={checkmobile}
                          id="mobile"
                          autoComplete="off"
                          value={mob}
                          onChange={handleChange}
                          // onKeyPress={(event) => {
                          //   if (!/[0-9]/.test(event.key)) {
                          //     event.preventDefault();
                          //   }
                          // }}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                        {step1submit && !mob && (
                          <span className="errortxt text-danger">
                            Mobile is required
                          </span>
                        )}
                        {moberror !== "" && mob && (
                          <span className="errortxt text-danger">
                            {moberror}
                          </span>
                        )}
                        {valmob !== "" && mob && moberror === "" && (
                          <span className="errortxt text-danger">{valmob}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          {strings.rmobile} :{" "}
                          <span className="text-danger">*</span>
                        </label>

                        <input
                          type="text"
                          placeholder="Enter Your Re Enter Mobile"
                          maxLength={10}
                          onBlur={checkremobile}
                          autoComplete="off"
                          id="remobile"
                          value={remob}
                          onChange={ReMobChange}
                          // onKeyPress={(event) => {
                          //   if (!/[0-9]/.test(event.key)) {
                          //     event.preventDefault();
                          //   }
                          // }}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                        {step1submit && !remob && (
                          <span className="errortxt text-danger">
                            Re Enter Mobile is required
                          </span>
                        )}
                        {remoberror !== "" && remob && (
                          <span className="errortxt text-danger">
                            {remoberror}
                          </span>
                        )}
                        {valremob !== "" && mob && remoberror === "" && (
                          <span className="errortxt text-danger">
                            {valremob}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          {strings.blood} :<b className="text-danger">*</b>{" "}
                        </label>
                        <select
                          value={blood}
                          onChange={(e) => {
                            setBlood(e.target.value);
                          }}
                          id="blood"
                        >
                          <option value="" disabled>
                            Select Your Blood Group
                          </option>
                          {blist.map((data, i) => {
                            return (
                              <option value={data.blooduid} key={i}>
                                {data.bloodtype}
                              </option>
                            );
                          })}
                        </select>
                        {step1submit && !blood && (
                          <span className="errortxt text-danger">
                            Blood Group is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">{strings.aadnum} :</label>
                        <input
                          type="text"
                          maxLength={12}
                          autoComplete="off"
                          id="aadno"
                          placeholder="Enter Your Aadhar Number"
                          // onKeyPress={(event) => {
                          //   if (!/[0-9]/.test(event.key)) {
                          //     event.preventDefault();
                          //   }
                          // }}
                          value={aadhar}
                          onChange={AadharChange}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                        {step1submit && aadhar && (
                          <span className="errortxt text-danger">
                            {aaderror}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          {strings.fatname} :<b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Your Father's Name"
                          onKeyPress={LetterOnly}
                          autoComplete="off"
                          id="faname"
                          onPaste={onPasteLetter}
                          value={father}
                          onChange={(e) => {
                            setFathar(e.target.value);
                          }}
                        />
                        {step1submit && !father && (
                          <span className="errortxt text-danger">
                            Father&apos;s Name is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">{strings.motname}</label>
                        <input
                          type="text"
                          id="moname"
                          placeholder="Enter Your Mother's Name"
                          onKeyPress={LetterOnly}
                          onPaste={onPasteLetter}
                          value={mother}
                          autoComplete="off"
                          onChange={(e) => {
                            setMothar(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <Link to={"/login"} className="btn btn-secondary" id="back">
                    {strings.backbutton}
                  </Link>

                  <a
                    className="btn btn-primary btn-next"
                    id="next1"
                    style={{ float: "right" }}
                    onClick={Next1step}
                  >
                    {strings.nextbutton}
                  </a>
                </div>
              </div>
            )}

            {/* Step Two */}
            {lstep === 2 && (
              <div className="form-step form-step-active">
                <div className="form-inner">
                  <div className="row">
                    <div
                      className="col-md-6"
                      style={{ boxShadow: "2px 0px 0px #c1c1c161" }}
                    >
                      <h4>{strings.permanent}</h4>
                      <div className="input-group">
                        <label htmlFor="username">
                          {strings.address1} :<b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Your Address 1"
                          id="peadd1"
                          value={padd1}
                          onChange={(e) => {
                            setPAdd1(e.target.value);
                          }}
                        />
                        {step2submit && !padd1 && (
                          <span className="errortxt text-danger">
                            Address 1 is required
                          </span>
                        )}
                      </div>
                      <div className="input-group">
                        <label htmlFor="username">{strings.address2} :</label>
                        <input
                          type="text"
                          placeholder="Enter Your Address 2"
                          id="peadd2"
                          value={padd2}
                          onChange={(e) => {
                            setPAdd2(e.target.value);
                          }}
                        />
                      </div>

                      <div className="input-group">
                        <label htmlFor="username">
                          {strings.state} :<b className="text-danger">*</b>
                        </label>
                        <select
                          value={pstate}
                          onChange={Selectpdist}
                          id="pestate"
                        >
                          <option value="" disabled>
                            Select Your State
                          </option>
                          {slist.map((data, i) => {
                            return (
                              <option value={data.stateuid} key={i}>
                                {data.statename}
                              </option>
                            );
                          })}
                        </select>
                        {step2submit && !pstate && (
                          <span className="errortxt text-danger">
                            State is required
                          </span>
                        )}
                      </div>
                      <div className="row mediarow">
                        <div className="col-md-12 col-lg-6">
                          <div className="input-group">
                            <label htmlFor="username">
                              {strings.district} :
                              <b className="text-danger">*</b>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Your District"
                              id="pedist"
                              value={pdist}
                              onChange={(e) => {
                                setPDist(e.target.value);
                              }}
                              onKeyPress={LetterOnly}
                              onPaste={onPasteLetter}
                            />
                            {/* <select
                              value={pdist}
                              onChange={(e) => {
                                setPDist(e.target.value);
                              }}
                              id="pedist"
                            >
                              <option value="" disabled>
                                Select Your District
                              </option>
                              {pdlist.map((data, i) => {
                                return (
                                  <option value={data.districtname} key={i}>
                                    {data.districtname}
                                  </option>
                                );
                              })}
                            </select> */}
                            {step2submit && !pdist && (
                              <span className="errortxt text-danger">
                                District is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                          <div className="input-group">
                            <label htmlFor="username">
                              {strings.pincode} :
                              <b className="text-danger">*</b>
                            </label>
                            <input
                              type="text"
                              maxLength={6}
                              id="pepin"
                              placeholder="Enter Your Pincode/Zipcode"
                              // onKeyPress={(event) => {
                              //   if (!/[0-9]/.test(event.key)) {
                              //     event.preventDefault();
                              //   }
                              // }}
                              value={ppin}
                              onChange={PerPinchange}
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                            />
                            {step2submit && !ppin && (
                              <span className="errortxt text-danger">
                                Pincode/Zipcode is required
                              </span>
                            )}
                            {step2submit &&
                              ppin !== "" &&
                              ppin.length !== 6 && (
                                <span className="errortxt text-danger">
                                  Pincode must be 6 digits
                                </span>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="checkbox checkbox-1">
                        <input
                          type="checkbox"
                          id="checkbox-1"
                          onChange={checkcomm}
                          checked={check}
                        />
                        <label htmlFor="checkbox-1">{strings.addrcheck}</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h4>{strings.communication}</h4>
                      <div className="input-group">
                        <label htmlFor="username">
                          {strings.address1} :<b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Your Address 1"
                          id="coadd1"
                          value={cadd1}
                          onChange={(e) => {
                            setCAdd1(e.target.value);
                          }}
                        />
                        {step2submit && !cadd1 && (
                          <span className="errortxt text-danger">
                            Address 1 is required
                          </span>
                        )}
                      </div>
                      <div className="input-group">
                        <label htmlFor="username">{strings.address2} :</label>
                        <input
                          type="text"
                          placeholder="Enter Your Address 2"
                          id="coadd2"
                          value={cadd2}
                          onChange={(e) => {
                            setCAdd2(e.target.value);
                          }}
                        />
                      </div>
                      <div className="input-group">
                        <label htmlFor="username">
                          {strings.state} :<b className="text-danger">*</b>
                        </label>
                        <select
                          value={cstate}
                          onChange={Selectcdist}
                          id="costate"
                        >
                          <option value="" disabled>
                            Select Your State
                          </option>
                          {slist.map((data, i) => {
                            return (
                              <option value={data.stateuid} key={i}>
                                {data.statename}
                              </option>
                            );
                          })}
                        </select>
                        {step2submit && !cstate && (
                          <span className="errortxt text-danger">
                            State is required
                          </span>
                        )}
                      </div>

                      <div className="row mediarow">
                        <div className="col-md-12 col-lg-6">
                          <div className="input-group">
                            <label htmlFor="username">
                              {strings.district} :
                              <b className="text-danger">*</b>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Your District"
                              id="codist"
                              value={cdist}
                              onChange={(e) => {
                                setCDist(e.target.value);
                              }}
                              onKeyPress={LetterOnly}
                              onPaste={onPasteLetter}
                            />
                            {/* <select
                              value={cdist}
                              onChange={(e) => {
                                setCDist(e.target.value);
                              }}
                              id="codist"
                            >
                              <option value="" disabled>
                                Select Your District
                              </option>
                              {cdlist.map((data, i) => {
                                return (
                                  <option value={data.districtname} key={i}>
                                    {data.districtname}
                                  </option>
                                );
                              })}
                            </select> */}
                            {step2submit && !cdist && (
                              <span className="errortxt text-danger">
                                District is required
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                          <div className="input-group">
                            <label htmlFor="username">
                              {strings.pincode} :
                              <b className="text-danger">*</b>
                            </label>
                            <input
                              type="text"
                              maxLength={6}
                              id="copin"
                              placeholder="Enter Your Pincode/Zipcode"
                              // onKeyPress={(event) => {
                              //   if (!/[0-9]/.test(event.key)) {
                              //     event.preventDefault();
                              //   }
                              // }}
                              value={cpin}
                              onChange={ComPinchange}
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                            />
                            {step2submit && !cpin && (
                              <span className="errortxt text-danger">
                                Pincode/Zipcode is required
                              </span>
                            )}
                            {step2submit &&
                              cpin !== "" &&
                              cpin.length !== 6 && (
                                <span className="errortxt text-danger">
                                  Pincode must be 6 digits
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <a
                    className="btn btn-prev btn-secondary"
                    id="prev1"
                    onClick={previous}
                  >
                    {strings.prevbutton}
                  </a>
                  <a
                    className="btn btn-next btn-primary"
                    id="next2"
                    style={{ float: "right" }}
                    onClick={Next1step}
                  >
                    {strings.nextbutton}
                  </a>
                </div>
              </div>
            )}

            {/* Step Three */}
            {lstep === 3 && (
              <div className="form-step form-step-active">
                <div className="form-inner">
                  <h4>
                    {strings.file}
                    <span>
                      ( {strings.filecdn} <b>{strings.fileformat}</b>{" "}
                      {strings.filesizecdn} <b>{strings.filesize}</b> )
                    </span>
                  </h4>

                  <div className="input-group form-upload">
                    <div className="row">
                      <div className="col-md-9">
                        <label>
                          1. {strings.photo} : <b className="text-danger">*</b>
                        </label>
                        <span className="desc">{strings.photocdn}</span>
                        <div className="mt-3">
                          <input
                            type="file"
                            name="file"
                            id="photo"
                            onChange={photofile}
                            accept=".png,.jpg,.jpeg"
                          />
                        </div>
                      </div>

                      {croppedImg !== "" && (
                        <div className="col-md-3">
                          <div className="imagecontainer">
                            <img src={croppedImg} width="100%" height="auto" />
                          </div>
                        </div>
                      )}
                      {formphoto === "" && (
                        <div className="col-md-3">
                          <div className="imagecontainer">
                            <img
                              src={require("../../assets/images/placeholder.png")}
                              width="100%"
                              height="auto"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {formphotoerror !== "" && (
                      <span className="errortxt text-danger">
                        {formphotoerror}
                      </span>
                    )}
                  </div>

                  <div className="input-group form-upload">
                    <div className="row">
                      <div className="col-md-9">
                        <label>
                          2. {strings.birth} : <b className="text-danger">*</b>
                        </label>
                        <span className="desc">{strings.birthcdn}</span>
                        <div className="mt-3">
                          <input
                            type="file"
                            name="file"
                            id="dob"
                            onChange={dobfile}
                            accept=".png,.jpg,.jpeg"
                          />
                        </div>
                      </div>
                      {formdob !== "" && (
                        <div className="col-md-3">
                          <div className="imagecontainer">
                            <img src={formdob} width="100%" height="auto" />
                          </div>
                        </div>
                      )}

                      {formdob === "" && (
                        <div className="col-md-3">
                          <div className="imagecontainer">
                            <img
                              src={require("../../assets/images/placeholder.png")}
                              width="100%"
                              height="auto"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {formdoberror !== "" && (
                      <span className="errortxt text-danger">
                        {formdoberror}
                      </span>
                    )}
                  </div>

                  <div className="input-group form-upload">
                    <div className="row">
                      <div className="col-md-9">
                        <label>
                          3. {strings.addrphoto} :{" "}
                          <b className="text-danger">*</b>
                        </label>
                        <span className="desc">{strings.addresscdn}</span>
                        <div className="mt-3">
                          <input
                            type="file"
                            name="file"
                            id="address"
                            onChange={addrfile}
                            accept=".png,.jpg,.jpeg"
                          />
                        </div>
                      </div>
                      {formaddr !== "" && (
                        <div className="col-md-3">
                          <div className="imagecontainer">
                            <img src={formaddr} width="100%" height="auto" />
                          </div>
                        </div>
                      )}
                      {formaddr === "" && (
                        <div className="col-md-3">
                          <div className="imagecontainer">
                            <img
                              src={require("../../assets/images/placeholder.png")}
                              width="100%"
                              height="auto"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {formaddrerror !== "" && (
                      <span className="errortxt text-danger">
                        {formaddrerror}
                      </span>
                    )}
                  </div>
                </div>
                <div className="mt-3">
                  <a
                    className="btn btn-prev btn-secondary"
                    id="prev2"
                    onClick={previous2}
                  >
                    {strings.prevbutton}
                  </a>
                  <a
                    className="btn btn-next btn-primary"
                    id="next3"
                    style={{ float: "right" }}
                    onClick={Next1step}
                  >
                    {strings.nextbutton}
                  </a>
                </div>
              </div>
            )}

            <Modal show={cshow} size="xl">
              <Modal.Header>
                <h6 className="rep-head">Crop Your Photograph</h6>
                <i
                  className="fa fa-window-close fa-lg"
                  onClick={setIClose}
                  aria-hidden="true"
                ></i>
              </Modal.Header>
              <Modal.Body className="mod-body">
                <Row>
                  <Col md={6}>
                    <Cropper
                      src={formphoto}
                      style={{ width: "100%" }}
                      initialAspectRatio={16 / 9}
                      guides={false}
                      crop={onCrop}
                      ref={cropperRef}
                    />
                  </Col>
                  <Col md={6}>
                    {croppedImg && (
                      <img
                        alt="Crop"
                        style={{ width: "100%" }}
                        src={croppedImg}
                      />
                    )}
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <button onClick={setIClose} className="text-center btn-info">
                  Ok
                </button>
              </Modal.Footer>
            </Modal>

            {/* Step Four */}
            {lstep === 4 && (
              <div className="form-step form-step-active">
                <div className="form-inner">
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <label htmlFor="">{strings.bplcard}</label>
                    </div>
                    <div className="col-md-6">
                      <div className="radioclass">
                        <input
                          type="radio"
                          id="no"
                          name="fav_language"
                          value="N"
                          checked={bplstatus === "N"}
                          onChange={Selectbplstatus}
                        />
                        <label htmlFor="no">No</label>
                        <input
                          type="radio"
                          id="yes"
                          name="fav_language"
                          value="Y"
                          checked={bplstatus === "Y"}
                          onChange={Selectbplstatus}
                        />
                        <label htmlFor="yes">Yes</label>
                      </div>
                    </div>
                  </div>
                  {bplstatus === "Y" && (
                    <div className="input-group form-upload">
                      <div className="row">
                        <div className="col-md-9">
                          <label htmlFor="dob">
                            4. {strings.bplphoto}
                            <b className="text-danger">*</b>
                          </label>
                          <span className="desc">{strings.bplcdn}</span>
                          <div className="mt-3">
                            <input
                              type="file"
                              name="file"
                              id="bplphoto"
                              onChange={bplfile}
                              accept=".png,.jpg,.jpeg"
                            />
                          </div>
                        </div>

                        {formbpl !== "" && (
                          <div className="col-md-3">
                            <div className="imagecontainer">
                              <img src={formbpl} width="100%" height="auto" />
                            </div>
                          </div>
                        )}

                        {formbpl === "" && (
                          <div className="col-md-3">
                            <div className="imagecontainer">
                              <img
                                src={require("../../assets/images/placeholder.png")}
                                width="100%"
                                height="auto"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      {formbplerror !== "" && (
                        <span className="errortxt text-danger">
                          {formbplerror}
                        </span>
                      )}
                    </div>
                  )}
                  <h4>{strings.disclaimer}</h4>

                  <div className="checkbox disclaimer checkbox-1">
                    <input
                      type="checkbox"
                      id="checkbox-2"
                      checked={term1checked}
                      onChange={checkterm1}
                    />
                    <label htmlFor="checkbox-2">
                      I certify that all the information is true to the best of
                      my knowledge <br />
                      मैं प्रमाणित करता हूं कि सभी जानकारी मेरे सर्वोत्तम ज्ञान
                      के अनुसार सही है !
                    </label>
                  </div>
                  <div className="checkbox disclaimer checkbox-1">
                    <input
                      type="checkbox"
                      id="checkbox-3"
                      checked={term2checked}
                      onChange={checkterm2}
                    />
                    <label htmlFor="checkbox-3">
                      {" "}
                      I agree that my AFI UID is liable to be cancelled if at
                      any stage discovered any of the above informaion is
                      incorrect <br /> मैं इस बात से सहमत हूं कि यदि किसी भी
                      स्तर पर उपरोक्त सूचनाओं में से कोई भी गलती निकली, तो मेरे
                      AFI UID को रद्द किया जा सकता है
                    </label>
                  </div>
                  <div className="checkbox disclaimer checkbox-1">
                    <input
                      type="checkbox"
                      id="checkbox-4"
                      checked={term3checked}
                      onChange={checkterm3}
                    />
                    <label htmlFor="checkbox-4">
                      * If your Application is rejected, From the time of
                      rejection mail the sent link for correction or updation of
                      document will be active only for 15 days. If you fail to
                      update within specific time the link will expire and
                      Athlete need to apply again as a new athlete. <br />
                      यदि आपका आवेदन अस्वीकार कर दिया जाता है, तो अस्वीकृति मेल
                      के समय से सुधार या दस्तावेज़ के अद्यतन के लिए भेजा गया
                      लिंक केवल 15 दिनों के लिए सक्रिय होगा। यदि आप विशिष्ट समय
                      के भीतर अपडेट करने में विफल रहते हैं तो लिंक समाप्त हो
                      जाएगा और एथलीट को एक नए एथलीट के रूप में फिर से आवेदन करने
                      की आवश्यकता होगी।
                    </label>
                  </div>
                  {discerror !== "" && (
                    <span className="errortxt text-danger">{discerror}</span>
                  )}
                </div>
                {errorMessage !== "" && (
                  <span className="errortxt text-danger">{errorMessage}</span>
                )}
                <div className="">
                  <a
                    className="btn btn-prev btn-secondary"
                    onClick={previous3}
                    id="prev3"
                  >
                    {strings.prevbutton}
                  </a>
                  <a
                    className="btn btn-next btn-primary"
                    id="next3"
                    style={{ float: "right" }}
                    onClick={Previewstep}
                  >
                    Preview
                  </a>
                </div>

                <Modal
                  show={show}
                  onHide={handleClose}
                  size="xl"
                  backdrop="static"
                >
                  <Modal.Header>
                    <h4>ID Card</h4>
                    <i
                      className="fa fa-window-close fa-lg"
                      aria-hidden="true"
                      onClick={handleClose}
                    ></i>
                  </Modal.Header>
                  <Modal.Body>
                    <div id="registrationpage">
                      <div className="registrationmain preview">
                        <div className="registrationright">
                          <div id="idcard">
                            <div className="id_main">
                              <div className="card card_left">
                                <div className="profil">
                                  <img
                                    // src={require("../../assets/images/user.png")}
                                    src={croppedImg}
                                    alt="user"
                                  />
                                </div>

                                <div className="area">
                                  <h5>
                                    {fname} {mname} {lname}
                                  </h5>
                                  <div className="id_content">
                                    <h6 className="id_dob">
                                      {moment(dob).format("DD-MM-YYYY")}
                                    </h6>
                                    <h6 className="id_gender">{gender}</h6>
                                  </div>
                                  <div className="id_uid">
                                    {gender === "Male" && (
                                      <h6>A{scode}MXXXXXX</h6>
                                    )}
                                    {gender === "Female" && (
                                      <h6>A{scode}FXXXXXX</h6>
                                    )}
                                  </div>
                                  <div className="id_signature">
                                    <img
                                      src={require("../../assets/images/signature.png")}
                                      height="20"
                                      alt="sign"
                                    />
                                    <h6>Ravinder Chaudhry</h6>
                                    <p>Secretary</p>
                                    <p>Authorised Signatory</p>
                                  </div>
                                </div>
                                <div className="id_preview">
                                  <h6 className="blink">preview</h6>
                                </div>
                              </div>
                              <div className="card card_right">
                                <div className="id_address">
                                  <h6>
                                    {padd1} {padd2} , {sname} , {pdist} {ppin}
                                  </h6>
                                </div>
                                <div className="id_qrcode">
                                  <img
                                    src={require("../../assets/images/qrcode.png")}
                                    alt="qrcode"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="id_final">
                              <div className="id_bottom">
                                <div className="checkbox checkbox-1">
                                  <input
                                    type="checkbox"
                                    id="checkbox-1"
                                    checked={term4checked}
                                    onChange={checkterm4}
                                  />
                                  <label htmlFor="checkbox-1">
                                    I agree that above given details are
                                    correct!
                                  </label>
                                </div>
                              </div>
                            </div>
                            {iderror !== "" && (
                              <span className="errortxt text-danger">
                                {iderror}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <a
                      className="btn btn-success mr-3"
                      style={{ float: "right" }}
                      onClick={regsubmit}
                    >
                      {strings.submitbutton}
                    </a>
                    <a
                      className="btn btn-next btn-secondary"
                      style={{ float: "right" }}
                      onClick={handleClose}
                    >
                      Cancel
                    </a>
                  </Modal.Footer>
                </Modal>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Athleteregister;
