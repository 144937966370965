export const MENUITEMS = [
  {
    Items: [
      {
        path: "/superadmin/dashboard",
        icon: "fa fa-home",
        type: "link",
        active: true,
        selected: true,
        title: "Dashboard",
      },

      {
        title: "Coaches",
        icon: "fa fa-users",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/superadmin/pendingcoaches",
            type: "link",
            active: false,
            selected: false,
            title: "Pending Coaches",
          },
          {
            path: "/superadmin/approvedcoaches",
            type: "link",
            active: false,
            selected: false,
            title: "Approved Coaches",
          },
          {
            path: "/superadmin/rejectedcoaches",
            type: "link",
            active: false,
            selected: false,
            title: "Rejected Coaches",
          },
          {
            path: "/superadmin/blockedcoaches",
            type: "link",
            active: false,
            selected: false,
            title: "Blocked Coaches",
          },
          {
            path: "/superadmin/allcoaches",
            type: "link",
            active: false,
            selected: false,
            title: "All Coaches",
          },
          {
            path: "/superadmin/coachdocupdate",
            type: "link",
            active: false,
            selected: false,
            title: "Document Updated Coaches",
          },
        
        ],
      },
    ],
  },
];
