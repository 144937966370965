import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate } from "react-router-dom";
//import imageUrl from '../../../ImageUrl';
import { coachdocupdated, docupdated } from "../../../services/superadmin";
import "./pendingathlete.css";
import moment from "moment/moment";
const DocUpdate = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const gridRef = useRef();
  var arr = [];
  const [urllink, setUrllink] = useState("");

  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    const path = link[1];
    setUrllink(path);
    if (path === "docupdate") {
      docupdated(localStorage.getItem("Userid")).then((response) => {
        if (response.status === true) {
          setFilterProduct([]);
          if (response.data.length !== 0) {
            for (let i = 0; i < response.data.length; i++) {
              var stat = "";
              if (response.data[i].improper_docs_updated === 0) {
                stat = "Waiting";
              } else {
                stat = "Updated";
              }
              arr.push({
                username: response.data[i].username,
                first_name: response.data[i].first_name,
                last_name: response.data[i].last_name,
                gender: response.data[i].gender,
                date_of_birth: response.data[i].date_of_birth,
                state: response.data[i].statename,
                mobile_no: response.data[i].mobile_no,
                email_id: response.data[i].email_id,
                registerdate: response.data[i].created_at,
                id: response.data[i].id,
                status: stat,
                user_id: response.data[i].user_id,
                order_id: response.data[i].order_id,
              });
            }
          }
          setFilterProduct(arr);
        }
      });
    }else if (path === "coachdocupdate") {
      coachdocupdated(localStorage.getItem("Userid")).then((response) => {
        if (response.status === true) {
          setFilterProduct([]);
          if (response.data.length !== 0) {
            for (let i = 0; i < response.data.length; i++) {
              var stat = "";
              if (response.data[i].improper_docs_updated === 0) {
                stat = "Waiting";
              } else {
                stat = "Updated";
              }
              arr.push({
                username: response.data[i].username,
                first_name: response.data[i].first_name,
                last_name: response.data[i].last_name,
                gender: response.data[i].gender,
                date_of_birth: response.data[i].date_of_birth,
                state: response.data[i].statename,
                mobile_no: response.data[i].mobile_no,
                email_id: response.data[i].email_id,
                registerdate: response.data[i].created_at,
                id: response.data[i].id,
                status: stat,
                user_id: response.data[i].user_id,
                order_id: response.data[i].order_id,
              });
            }
          }
          setFilterProduct(arr);
        }
      });
    }
  }, [window.location.href]);

  const columnDefs = [
    {
      headerName: "Reg No",
      width: 150,
      field: "username",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "First Name",
      width: 170,
      field: "first_name",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Last Name",
      width: 170,
      field: "last_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "D.O.B",
      width: 150,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },

    {
      headerName: "State",
      width: 150,
      field: "state",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      width: 150,
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      width: 150,
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOR",
      width: 150,
      field: "registerdate",
      sortable: true,
      filter: true,
      resizable: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value !== null) {
          return moment(data.value).format("DD-MM-YYYY");
        }
      },
    },
    {
      headerName: "Order Id",
      width: 300,
      field: "order_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      width: 150,
      field: "status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Actions",
      width: 100,
      field: "user_id",
      filter: false,
      resizable: true,
      cellRenderer: function (params) {
        return (
          <Link
            to={"/superadmin/viewprofile/" + params.value}
            className="btn-sm btn-info"
          >
            <i className="fa fa-eye fa-lg"></i>
          </Link>
        );
      },
    },
  ];


  const columnCDefs = [
    {
      headerName: "Reg No",
      width: 150,
      field: "username",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "First Name",
      width: 170,
      field: "first_name",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Last Name",
      width: 170,
      field: "last_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "D.O.B",
      width: 150,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },

    {
      headerName: "State",
      width: 150,
      field: "state",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      width: 150,
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      width: 150,
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOR",
      width: 150,
      field: "registerdate",
      sortable: true,
      filter: true,
      resizable: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value !== null) {
          return moment(data.value).format("DD-MM-YYYY");
        }
      },
    },
    {
      headerName: "Order Id",
      width: 300,
      field: "order_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      width: 150,
      field: "status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Actions",
      width: 100,
      field: "user_id",
      filter: false,
      resizable: true,
      cellRenderer: function (params) {
        return (
          <Link
            to={"/superadmin/viewcoaches/" + params.value}
            className="btn-sm btn-info"
          >
            <i className="fa fa-eye fa-lg"></i>
          </Link>
        );
      },
    },
  ];
  const navigate = useNavigate();

  const onBtnUpdate = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);
  return (
    <Fragment>
      <div className="page-header reported-header">
        {urllink === "docupdate" &&
 <h2 className="main-content-title tx-24 mg-b-5">
 Document Updated Athletes
</h2>
        }
         {urllink === "coachdocupdate" &&
 <h2 className="main-content-title tx-24 mg-b-5">
 Document Updated Coaches
</h2>
}
         
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                {/* <div className="res-right">
                  <a className="btn-exp" onClick={onBtnUpdate}>
                    Export
                  </a>
                </div> */}
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={urllink ==="docupdate" ? columnDefs : columnCDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DocUpdate;
