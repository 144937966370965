import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { athleteupdate, coachdetails, coachupdate } from "../../../services/superadmin";
import {
  bloodlist,
  distlist,
  statelist,
} from "../../../services/registerservice";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";

const EditCoach = () => {
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [dob, setDOB] = useState("");
  const [blood, setBlood] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [commaddr, setCommAddr] = useState("");
  const [commaddr2, setCommaddr2] = useState("");
  const [addr, setAddr] = useState("");
  const [addr2, setAddr2] = useState("");
  const [occu, setOccu] = useState("");

  const [pdistname, setDistname] = useState("");
  const [ppin, setPincode] = useState("");
  const [uid, setUid] = useState("");
  const [state, setState] = useState("");
  const [status, setStatus] = useState("");
  const [formphoto, setPhotoFile] = useState("");
  const [formid, setIDFile] = useState("");
  const [formbpl, setBpl] = useState("");
  const [bplstatus, setBplstatus] = useState("");
  const [formaddr, setAddrFile] = useState("");
  const [Errmsg, setErrMsg] = useState("");

  const [cpath, setCPath] = useState("");

  const [cdateyear, setCYearlist] = useState([]);
  const [rows, setRows] = useState([]);
  const [cspecific, setCSpecific] = useState("");
  const [cplace, setCPlace] = useState("");
  const [caddress, setCAddress] = useState("");

  const [smale, setSMale] = useState("");
  const [sfemale, setSFemale] = useState("");
  const [jmale, setJMale] = useState("");
  const [jfemale, setJFemale] = useState("");

  const [dmale, setDMale] = useState("");
  const [umale, setUMale] = useState("");
  const [nmale, setNMale] = useState("");
  const [imale, setIMale] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function LastNameOnly(eve) {
    if (eve.key !== ".") {
      if (/^[a-zA-Z\s]*$/.test(eve.key)) {
        return true;
      } else {
        eve.preventDefault();
        return false;
      }
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  const [blist, setBloodlist] = useState([]);
  const [slist, setStatelist] = useState([]);
  const [pdlist, setPDList] = useState([]);
  const [cdlist, setCDList] = useState([]);

  const [pdist, setPDist] = useState("");
  const [pstate, setPState] = useState("");
  const [cdist, setCDist] = useState("");
  const [cpin, setCPin] = useState("");
  const [cstate, setCState] = useState("");
  const [check, setCheck] = useState("");

  function checkcomm(e) {
    setCheck(e.target.checked);
    setCommAddr("");
    setCommaddr2("");
    setCDist("");
    setCPin("");
    setCState("");
    if (e.target.checked === true) {
      setCommAddr(addr);
      setCommaddr2(addr2);
      setCDist(pdist);
      setCPin(ppin);
      setCState(pstate);
      setCDList([]);
      distlist(pstate).then((response) => {
        setCDList(response.data);
      });
    } else {
      setCommAddr("");
      setCommaddr2("");
      setCDist("");
      setCPin("");
      setCState("");
    }
  }

  ///Permanent District
  function Selectpdist(e) {
    setPState(e.target.value);
    setPDist("");
    distlist(e.target.value).then((response) => {
      setPDList(response.data);
    });
  }

  ///Communication District
  function Selectcdist(e) {
    setCState(e.target.value);
    setCDist("");
    distlist(e.target.value).then((response) => {
      setCDList(response.data);
    });
  }
  // Photograph
  const [formphotoerror, setPhotoErrors] = useState("");
  const [formphotoupload, setPhotoUpload] = useState("");

  const [cshow, setCShow] = useState(false);
  function setIShow() {
    setCShow(true);
  }
  const setIClose = () => setCShow(false);

  const cropperRef = useRef(null);
  const [croppedImg, setCroppedImg] = useState("");
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedImg(cropper.getCroppedCanvas().toDataURL());

    const base64 = cropper.getCroppedCanvas().toDataURL();

    const current = new Date();
    const timestamp = current.getTime();
    const imageName = first + timestamp + ".png";
    const arr = base64.split(",");
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    var pfile = new File([u8arr], imageName, { type: "image/png" });
    setPhotoUpload(new File([u8arr], imageName, { type: "image/png" }));

    var size = pfile.size / 1024 / 1024;
    if (size > 5) {
      setPhotoErrors("Photograph size should be less than 5MB");
    }
  };

  function photofile(event) {
    setPhotoErrors("");
    setPhotoFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoFile(URL.createObjectURL(event.target.files[0]));
        // setPhotoUpload(event.target.files[0]);
        setIShow();
      } else {
        setErrMsg("Photograph should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setErrMsg("Photograph size should be less than 3MB");
      }
    }
  }

  // DOB Proof
  const [formdobupload, setDobUpload] = useState("");

  function dobfile(event) {
    setIDFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setIDFile(URL.createObjectURL(event.target.files[0]));
        setDobUpload(event.target.files[0]);
      } else {
        setErrMsg("DOB Proof should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setErrMsg("DOB Proof size should be less than 3MB");
      }
    }
  }

  // Address
  const [formaddrupload, setAddrUpload] = useState("");

  function addrfile(event) {
    setAddrFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setAddrFile(URL.createObjectURL(event.target.files[0]));
        setAddrUpload(event.target.files[0]);
      } else {
        setErrMsg("Address Proof should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setErrMsg("Address Proof size should be less than 3MB");
      }
    }
  }

  // BPL Status
  function Selectbplstatus(event) {
    setBplstatus(event.target.value);
    // if (event.target.value === "N") {
    // setBPLFile("");
    // }
  }

  // BPL Card
  const [formbplupload, setBPLUpload] = useState("");

  function bplfile(event) {
    setBpl("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setBpl(URL.createObjectURL(event.target.files[0]));
        setBPLUpload(event.target.files[0]);
      } else {
        setErrMsg("BPL Card should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setErrMsg("BPL Card size should be less than 3MB");
      }
    }
  }

  function updateathlete(e) {
    e.preventDefault();
    if (blood !== "") {
        const tempData = new FormData();
        tempData.append("user_id", id);
        tempData.append("first_name", first);
        tempData.append("middle_name", "");
        tempData.append("last_name", last);
        tempData.append("date_of_birth", dob);
        tempData.append("gender", gender);
        tempData.append("blood_group", blood);
        tempData.append("aadhaar_no", aadhar);
        tempData.append("email_id", email);
        tempData.append("mobile_no", mobile);
        tempData.append("occupation", occu);
      tempData.append("father_name", "-");
      tempData.append("mother_name", "-");

      tempData.append("below_poverty_card", "-");
        tempData.append("permenant_address_one", addr);
        tempData.append("permenant_address_two", addr2);
        tempData.append("permenant_state", pstate);
        tempData.append("permenant_district", pdist);
        tempData.append("permenant_zipcode", ppin);
        tempData.append("communication_address_one", commaddr);
        tempData.append("communication_address_two", commaddr2);
        tempData.append("communication_state", cstate);
        tempData.append("communication_district", cdist);
        tempData.append("communication_zipcode", cpin);

        tempData.append("certificate_selected_count", rows.length);
      for (let i = 0; i < rows.length; i++) {
        tempData.append("level_" + [i + 1], rows[i].clevel);
        tempData.append("year_of_passing_" + [i + 1], rows[i].cyeargroup);
        tempData.append("certificate_image_" + [i + 1], rows[i].ccert);
      }
      tempData.append("coaching_specification", cspecific);
      tempData.append("place_of_coacing", cplace);
      tempData.append("address_of_coaching", caddress);
      tempData.append("senior_male_count", smale);
      tempData.append("senior_female_count", sfemale);
      tempData.append("junior_male_count", jmale);
      tempData.append("junior_female_count", jfemale);
      tempData.append("school_or_district_count", dmale);
      tempData.append("university_or_state_count", umale);
      tempData.append("national_level_count", nmale);
      tempData.append("international_level_count", imale);

        tempData.append("photograph", formphotoupload);
        tempData.append("address_proof", formaddrupload);
        tempData.append("dob_proof", formdobupload);
        coachupdate(tempData).then((response) => {
          if (response.status === true) {
            navigate("/superadmin/viewcoaches/" + id);
          } else {
            setErrMsg(response.message);
          }
        });
    } else {
      setErrMsg("Please Select Blood Group");
    }
  }
  const [subrole, setSubRole] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("SubRoleid") === "1") {
      setSubRole(false);
    } else if (localStorage.getItem("SubRoleid") === "2") {
      setSubRole(false);
    } else {
      setSubRole(true);
    }
    let chyear = [];
    for (let i = 1950; i <= new Date().getFullYear(); i++) {
      chyear.push(i);
    }
    setCYearlist(chyear);
    coachdetails(id).then((response) => {
      if (response.status === true) {
        setFirst(response.data.details.first_name);
        setLast(response.data.details.last_name);
        setDOB(response.data.details.date_of_birth);
        setGender(response.data.details.gender);
        setEmail(response.data.details.email_id);
        setMobile(response.data.details.mobile_no);
        if (response.data.details.blood_group_uid !== null) {
          setBlood(response.data.details.blood_group_uid);
        }
        if (response.data.details.aadhaar_no !== null) {
          setAadhar(response.data.details.aadhaar_no);
        }
        setCommAddr(response.data.details.communication_address_one);
        if (response.data.details.communication_address_two !== null) {
          setCommaddr2(response.data.details.communication_address_two);
        }
        setCDist(response.data.details.communication_district);
        setCState(response.data.details.communication_state);
        setCPin(response.data.details.communication_zipcode);
        setAddr(response.data.details.permenant_address_one);
        if (response.data.details.permenant_address_two !== null) {
          setAddr2(response.data.details.permenant_address_two);
        }
        setPDist(response.data.details.permenant_district);
        setPState(response.data.details.permenant_state);

        setDistname(response.data.details.districtname);
        setPincode(response.data.details.permenant_zipcode);
        setState(response.data.details.statename);
        setStatus(response.data.details.status);
        setPhotoFile(
          response.data.photo_path + response.data.details.photograph
        );
        setIDFile(
          response.data.id_proof_path + response.data.details.dob_proof
        );
        setAddrFile(
          response.data.address_proof_path + response.data.details.address_proof
        );

        if (response.data.coach_other_details !== null) {
          setOccu(response.data.coach_other_details.occupation);
          setCSpecific(
            response.data.coach_other_details.coaching_specification
          );
          setCPlace(response.data.coach_other_details.place_of_coacing);
          setCAddress(response.data.coach_other_details.address_of_coaching);
          setSMale(response.data.coach_other_details.senior_male_count);
          setSFemale(response.data.coach_other_details.senior_female_count);
          setJMale(response.data.coach_other_details.junior_male_count);
          setJFemale(response.data.coach_other_details.junior_female_count);
          setDMale(response.data.coach_other_details.school_or_district_count);
          setUMale(response.data.coach_other_details.university_or_state_count);
          setNMale(response.data.coach_other_details.national_level_count);
          setIMale(response.data.coach_other_details.international_level_count);
        }

        if (response.data.coach_certificates.length !== 0) {
          let arr = [];
          for (let i = 0; i < response.data.coach_certificates.length; i++) {
            arr.push({
              clevel: response.data.coach_certificates[i].level,
              cyeargroup: response.data.coach_certificates[i].year_of_passing,
              ccert: response.data.coach_certificates[i].certificate_path,
              certerr: "",
            });
          }
          setRows(arr);
          setCPath(response.data.certificate_path);
        }
        setUid(response.data.details.username);
        distlist(response.data.details.permenant_state).then((response1) => {
          setPDList(response1.data);
        });

        distlist(response.data.details.communication_state).then(
          (response1) => {
            setCDList(response1.data);
          }
        );

        registerlist();
      }
    });
  }, []);

  function registerlist() {
    bloodlist().then((response) => {
      if (response.status === true) {
        setBloodlist(response.data);
      }
    });
    statelist().then((response) => {
      if (response.status === true) {
        setStatelist(response.data);
      }
    });
  }

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/placeholder.png");
    ev.target.onerror = null;
  }

  const [file, setFile] = useState("");
  const [show, setShow] = useState(false);
  function handleshow(img) {
    setFile("");
    setFile(img);
    setShow(true);
  }

  function handleclose() {
    setShow(false);
  }

  const handleAddRow = () => {
    setRows([...rows, { clevel: "", cyeargroup: "", ccert: "", certerr: "" }]);
  };

  // Handler for removing a row
  const handleRemoveRow = (index) => {
    setRows(rows.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, e) => {
    const updatedRows = [...rows];
    const isValid = rows.some((row) => row.clevel == e.target.value);
    if (!isValid) {
      updatedRows[index].clevel = e.target.value;
      setRows(updatedRows);
    } else {
      toast.error(e.target.value + " has already been added");
    }
  };

  const handleYearChange = (index, e) => {
    const updatedRows = [...rows];
    updatedRows[index].cyeargroup = e.target.value;
    setRows(updatedRows);
  };

  const handleFileChange = (index, e) => {
    const updatedRows = [...rows];
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        updatedRows[index].ccert = e.target.files[0];
        setRows(updatedRows);
      } else {
        updatedRows[index].certerr =
          "Certificate should be jpg or png or jpeg format";
      }
      var size = e.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        updatedRows[index].certerr = "Certificate size should be less than 5MB";
      }
    }
  };

  const handleSMale = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setSMale(e.target.value);
    }
  };

  const handleSFemale = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setSFemale(e.target.value);
    }
  };

  const handleJMale = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setJMale(e.target.value);
    }
  };

  const handleJFemale = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setJFemale(e.target.value);
    }
  };

  const handleUMale = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setUMale(e.target.value);
    }
  };

  const handleDMale = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setDMale(e.target.value);
    }
  };

  const handleNMale = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setNMale(e.target.value);
    }
  };

  const handleIMale = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setIMale(e.target.value);
    }
  };

  return (
    <Fragment>
      <div className="page-header reported-header">
        <h2 className="main-content-title tx-24 mg-b-5">Edit Coach</h2>
      </div>{" "}
      <Card className=" custom-card overflow-hidden">
        <Card.Body>
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">First Name</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your First Name"
                            onKeyPress={LetterOnly}
                            onPaste={onPasteLetter}
                            value={first}
                            onChange={(e) => setFirst(e.target.value)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Last Name</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Last/Family Name"
                            value={last}
                            onChange={(e) => setLast(e.target.value)}
                            onKeyPress={LastNameOnly}
                            onPaste={onPasteLetter}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Date of Birth</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="date"
                            placeholder="Enter Your Date of Birth"
                            value={dob}
                            onChange={(e) => setDOB(e.target.value)}
                            autoComplete="off"
                            onKeyPress={(event) => {
                              event.preventDefault();
                            }}
                            onKeyDown={(event) => {
                              event.preventDefault();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Gender</b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={gender}
                            onChange={(e) => {
                              setGender(e.target.value);
                            }}
                            id="gender"
                            disabled
                          >
                            <option value="" disabled>
                              Select Your Gender
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Email</b>
                        </div>
                        <div className="col-md-8">
                          {!subrole && (
                            <input
                              type="text"
                              placeholder="Enter Your Email"
                              autoComplete="off"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />
                          )}
                          {subrole && (
                            <input
                              type="text"
                              placeholder="Enter Your Email"
                              autoComplete="off"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              readOnly
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Blood Group</b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={blood}
                            onChange={(e) => {
                              setBlood(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your Blood Group
                            </option>
                            {blist.map((data, i) => {
                              return (
                                <option value={data.blooduid} key={i}>
                                  {data.bloodtype}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Mobile</b>
                        </div>
                        <div className="col-md-8">
                          {!subrole && (
                            <input
                              type="text"
                              placeholder="Enter Your Mobile Number"
                              maxLength={10}
                              autoComplete="off"
                              value={mobile}
                              onChange={(e) => {
                                setMobile(e.target.value);
                              }}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          )}
                          {subrole && (
                            <input
                              type="text"
                              placeholder="Enter Your Mobile Number"
                              maxLength={10}
                              autoComplete="off"
                              value={mobile}
                              onChange={(e) => {
                                setMobile(e.target.value);
                              }}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              readOnly
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Aadhar Number</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Aadhar Number"
                            maxLength={16}
                            autoComplete="off"
                            value={aadhar}
                            onChange={(e) => {
                              setAadhar(e.target.value);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Occupation</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Occupation"
                            onKeyPress={LetterOnly}
                            autoComplete="off"
                            onPaste={onPasteLetter}
                            value={occu}
                            onChange={(e) => {
                              setOccu(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <hr></hr>
                <div className="row">
                  <div className="col-md-6">
                    <h6>PERMANENT ADDRESS</h6>
                  </div>
                  <div className="col-md-6">
                    <h6>COMMUNICATION ADDRESS</h6>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 1</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Address 1"
                            value={addr}
                            onChange={(e) => {
                              setAddr(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 1</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Address 1"
                            value={commaddr}
                            onChange={(e) => {
                              setCommAddr(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 2</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Address 2"
                            value={addr2}
                            onChange={(e) => {
                              setAddr2(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">ADDRESS 2</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Address 2"
                            value={commaddr2}
                            onChange={(e) => {
                              setCommaddr2(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">State</b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={pstate}
                            onChange={Selectpdist}
                            disabled
                          >
                            <option value="" disabled>
                              Select Your State
                            </option>
                            {slist.map((data, i) => {
                              return (
                                <option value={data.stateuid} key={i}>
                                  {data.statename}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">State</b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={cstate}
                            onChange={Selectcdist}
                            disabled
                          >
                            <option value="" disabled>
                              Select Your State
                            </option>
                            {slist.map((data, i) => {
                              return (
                                <option value={data.stateuid} key={i}>
                                  {data.statename}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">District</b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={pdist}
                            onChange={(e) => {
                              setPDist(e.target.value);
                            }}
                            disabled
                          >
                            <option value="" disabled>
                              Select Your District
                            </option>
                            {pdlist.map((data, i) => {
                              return (
                                <option value={data.districtname} key={i}>
                                  {data.districtname}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">District</b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={cdist}
                            onChange={(e) => {
                              setCDist(e.target.value);
                            }}
                            disabled
                          >
                            <option value="" disabled>
                              Select Your District
                            </option>
                            {cdlist.map((data, i) => {
                              return (
                                <option value={data.districtname} key={i}>
                                  {data.districtname}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Pincode</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            maxLength={6}
                            placeholder="Enter Your Pincode/Zipcode"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={ppin}
                            onChange={(e) => {
                              setPincode(e.target.value);
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Pincode</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            maxLength={6}
                            placeholder="Enter Your Pincode/Zipcode"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={cpin}
                            onChange={(e) => {
                              setCPin(e.target.value);
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="checkbox checkbox-1 rhk-gap mt-4">
                  <input
                    type="checkbox"
                    id="checkbox-1"
                    className="reg-chk"
                    onChange={checkcomm}
                    checked={check}
                  />
                  <label htmlFor="checkbox-1">
                    Communication address is same as permanent address
                  </label>
                </div>

                <br />

                <hr />
                {/* <div className="row">
                  <div className="col-md-12">
                    <h5>COACH QUALIFICATIONS</h5>
                  </div>

                  <div className="col-md-12">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Coaching Specialization (if any)
                          </b>
                        </div>
                        <div className="col-md-8">
                          <textarea
                            rows="3"
                            placeholder="Enter Your Coaching Specialization (if any)"
                            value={cspecific}
                            onChange={(e) => setCSpecific(e.target.value)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {rows.map((row, index) => (
                  <div className="row" key={index}>
                    <div className="col-md-3 col-lg-3">
                      <div className="input-group">
                        <label htmlFor="username">
                          Level
                          <b className="text-danger">*</b>
                        </label>
                        <select
                          value={row.clevel}
                          onChange={(e) => handleInputChange(index, e)}
                          id={`level-${index}`}
                        >
                          <option value="" disabled>
                            Select Your Level
                          </option>
                          <option value="Level 1">Level 1</option>
                          <option value="Level 2">Level 2</option>
                          <option value="Pre Level 1">Pre Level 1</option>
                          <option value="NIS Full Time">NIS Full Time</option>
                          <option value="NIS Part Time">NIS Part Time</option>
                          <option value="State">State</option>
                          <option value="National">National</option>
                          <option value="International">International</option>
                          <option value="PET Teacher">PET Teacher</option>
                          <option value="Parents">Parents</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3 col-lg-3">
                      <div className="input-group">
                        <label htmlFor="username">
                          Year of Passing :<b className="text-danger">*</b>
                        </label>
                        <select
                          value={row.cyeargroup}
                          onChange={(e) => handleYearChange(index, e)}
                          id={`yeargroup-${index}`}
                        >
                          <option value="" disabled>
                            Year
                          </option>
                          {cdateyear.map((data, i) => {
                            return (
                              <option value={data} key={i}>
                                {data}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Certificate</b>
                        </div>
                        <div className="col-md-8">
                          <img
                            className="profile_img"
                            src={cpath + row.ccert}
                            alt="photo"
                            onClick={() =>
                              handleshow(cpath + row.ccert)
                            }
                            onError={(e) => errorhandle(e)}
                          />
                          <input
                            type="file"
                            name="file"
                            onChange={(e) => handleFileChange(index, e)}
                            accept=".png,.jpg,.jpeg"
                          />
                          {row.ccert !== "" && row.certerr !== "" && (
                            <span className="errortxt text-danger">
                              {row.certerr}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2 text-right mt-5">
                      {index == 0 && rows.length < 11 && (
                        <a
                          className="btn-sm btn-success"
                          onClick={handleAddRow}
                        >
                          <i className="fa fa-plus fa-lg"></i>
                        </a>
                      )}
                      {index != 0 && (
                        <a
                          className="btn-sm btn-danger"
                          onClick={() => handleRemoveRow(index)}
                        >
                          <i className="fa fa-trash fa-lg"></i>
                        </a>
                      )}
                    </div>
                  </div>
                ))}
                <hr />
                <div className="row">
                  <div className="col-md-12">
                    <h5>COACHING DETAILS</h5>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Place of Coaching</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Place of Coaching"
                            value={cplace}
                            onChange={(e) => {
                              setCPlace(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address of Coaching Center</b>
                        </div>
                        <div className="col-md-8">
                        <textarea
                          rows="3"
                          placeholder="Enter Your Address Of Coahing Center"
                          value={caddress}
                          onChange={(e) => setCAddress(e.target.value)}
                          id="caddress"
                          autoComplete="off"
                        />
                        </div>
                      </div>
                    </div>
                  </div>
                
                  <div className="col-md-12">
                    <h5>Total number of athletes present under you</h5>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Senior Male</b>
                        </div>
                        <div className="col-md-8">
                        <input
                          type="text"
                          placeholder="Enter Your Senior Male"
                          value={smale}
                          onChange={handleSMale}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          id="smale"
                          autoComplete="off"
                        />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Senior Female</b>
                        </div>
                        <div className="col-md-8">
                        <input
                          type="text"
                          placeholder="Enter Your Senior Male"
                          value={sfemale}
                          onChange={handleSFemale}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          id="sfemale"
                          autoComplete="off"
                        />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Junior Male</b>
                        </div>
                        <div className="col-md-8">
                        <input
                          type="text"
                          placeholder="Enter Your Junior Male"
                          value={jmale}
                          onChange={handleJMale}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          id="jmale"
                          autoComplete="off"
                        />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Junior Female</b>
                        </div>
                        <div className="col-md-8">
                        <input
                          type="text"
                          placeholder="Enter Your Junior Female"
                          value={jfemale}
                          onChange={handleJFemale}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          id="jmale"
                          autoComplete="off"
                        />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">School/ District</b>
                        </div>
                        <div className="col-md-8">
                        <input
                          type="text"
                          placeholder="Enter Your School/ District"
                          value={dmale}
                          onChange={handleDMale}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          id="smale"
                          autoComplete="off"
                        />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">University/ State</b>
                        </div>
                        <div className="col-md-8">
                        <input
                          type="text"
                          placeholder="Enter Your University/ State"
                          value={umale}
                          onChange={handleUMale}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          id="smale"
                          autoComplete="off"
                        />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">National level</b>
                        </div>
                        <div className="col-md-8">
                        <input
                          type="text"
                          placeholder="Enter Your National level"
                          value={nmale}
                          onChange={handleNMale}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          id="smale"
                          autoComplete="off"
                        />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">International level</b>
                        </div>
                        <div className="col-md-8">
                        <input
                          type="text"
                          placeholder="Enter Your International level"
                          value={imale}
                          onChange={handleIMale}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          id="smale"
                          autoComplete="off"
                        />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr></hr> */}

                <div className="row mt-4">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Photograph</b>
                      </div>
                      <div className="col-md-8">
                        <img
                          className="profile_img"
                          src={formphoto}
                          alt="photo"
                          onClick={() => handleshow(formphoto)}
                          onError={(e) => errorhandle(e)}
                        />

                        <input
                          type="file"
                          name="file"
                          onChange={photofile}
                          accept=".png,.jpg,.jpeg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">ID Proof</b>
                      </div>
                      <div className="col-md-8">
                        <img
                          className="profile_img"
                          src={formid}
                          alt="photo"
                          onClick={() => handleshow(formid)}
                          onError={(e) => errorhandle(e)}
                        />
                        <input
                          type="file"
                          name="file"
                          onChange={dobfile}
                          accept=".png,.jpg,.jpeg"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Address Proof</b>
                      </div>
                      <div className="col-md-8">
                        <img
                          className="profile_img"
                          src={formaddr}
                          alt="photo"
                          onClick={() => handleshow(formaddr)}
                          onError={(e) => errorhandle(e)}
                        />
                        <input
                          type="file"
                          name="file"
                          onChange={addrfile}
                          accept=".png,.jpg,.jpeg"
                        />
                      </div>
                    </div>
                  </div>
                  {bplstatus === "Y" && (
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">BPL Card</b>
                        </div>
                        <div className="col-md-8">
                          <img
                            className="profile_img"
                            src={formbpl}
                            alt="photo"
                            onClick={() => handleshow(formbpl)}
                            onError={(e) => errorhandle(e)}
                          />

                          <input
                            type="file"
                            name="file"
                            onChange={bplfile}
                            accept=".png,.jpg,.jpeg"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <Modal show={cshow} size="xl">
                  <Modal.Header>
                    <h6 className="rep-head">Crop Your Photograph</h6>
                    <i
                      className="fa fa-window-close fa-lg"
                      onClick={setIClose}
                      aria-hidden="true"
                    ></i>
                  </Modal.Header>
                  <Modal.Body className="mod-body">
                    <Row>
                      <Col md={6}>
                        <Cropper
                          src={formphoto}
                          style={{ width: "100%" }}
                          initialAspectRatio={16 / 9}
                          guides={false}
                          crop={onCrop}
                          ref={cropperRef}
                        />
                      </Col>
                      <Col md={6}>
                        {croppedImg && (
                          <img
                            alt="Crop"
                            style={{ width: "100%" }}
                            src={croppedImg}
                          />
                        )}
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      onClick={setIClose}
                      className="text-center btn-info"
                    >
                      Ok
                    </button>
                  </Modal.Footer>
                </Modal>

                <Modal show={show}>
                  <Modal.Body>
                    <img
                      src={file}
                      alt="Image"
                      onError={(e) => errorhandle(e)}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <a
                      className="btn btn-next btn-success"
                      style={{ float: "right" }}
                      href={file}
                      download
                      target="_blank"
                    >
                      Download
                    </a>
                    <a
                      className="btn btn-next btn-secondary"
                      style={{ float: "right" }}
                      onClick={handleclose}
                    >
                      Close
                    </a>
                  </Modal.Footer>
                </Modal>
                {Errmsg !== "" && <span className="text-danger">{Errmsg}</span>}
                <hr />
                <div className="text-right">
                  <button
                    className="btn-f btn-secondary"
                    onClick={() => navigate("/superadmin/viewcoaches/" + id)}
                  >
                    back
                  </button>
                  <button className="btn-f btn-success" onClick={updateathlete}>
                    Submit
                  </button>
                </div>

                <br />
                <hr></hr>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default EditCoach;
