import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "../dashboard.scss";
import imageUrl from "../../../ImageUrl";
import fileString from "../../../assets/language/filepath.json";

function CoachDashboard() {
  const [filterProduct, setFilterProduct] = useState([]);

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/logo/logo-small.png");
    ev.target.onerror = null;
  }

 
  return (
    <Fragment>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Dashboard</h2>
        </div>
      </div>
      {/* <!-- End Page Header --> */}

      <Row className=" row-sm  mt-lg-4">
        <Col sm={12} lg={12} xl={12}>
          <Card className="bg-black-2 custom-card card-box">
            <Card.Body className="p-4">
              <Row className="align-items-center">
                <Col
                  sm={{ offset: 6 }}
                  xl={{ offset: 3 }}
                  className="col-xl-8 col-sm-6 col-12 img-bg "
                >
                  <h4 className="d-flex  mb-3">
                    <span className="font-weight-bold text-dark ">
                      {localStorage.getItem("User")}
                    </span>
                  </h4>
                  <p className="tx-black-5 mb-1">
                    Athletics Federation of India
                  </p>
                </Col>
                <img
                  src={require("../../../assets/images/athlete.png")}
                  alt="athlete"
                />
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      
    </Fragment>
  );
}

CoachDashboard.propTypes = {};

CoachDashboard.defaultProps = {};

export default CoachDashboard;
