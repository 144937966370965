import React, { Fragment, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Table, Row, Col, Form, Modal } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  holdpop,
  activeplayer,
  sendcredentials,
  rejectedreasons,
  coachdetails,
  coachapprove,
  coachreject,
  coachblock,
} from "../../../services/superadmin";
import moment from "moment/moment";
const ViewCoach = () => {
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [dob, setDOB] = useState("");
  const [dor, setDOR] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [blood, setBlood] = useState("");
  const [aadhar, setAadhar] = useState("");

  const [commaddr, setCommAddr] = useState("");
  const [commaddr2, setCommaddr2] = useState("");
  const [commstate, setCommState] = useState("");
  const [commdistname, setCommDistname] = useState("");
  const [commpin, setCommPincode] = useState("");
  const [addr, setAddr] = useState("");
  const [addr2, setAddr2] = useState("");
  const [state, setState] = useState("");
  const [pdistname, setDistname] = useState("");
  const [ppin, setPincode] = useState("");
  const [occu, setOccu] = useState("");

  const [uid, setUid] = useState("");
  const [status, setStatus] = useState("");
  const [formphoto, setPhotoFile] = useState("");
  const [formid, setIDFile] = useState("");
  const [formaddr, setAddrFile] = useState("");
  const [passApprove, setPassApprove] = useState("");
  const [Errmsg, setErrMsg] = useState("");
  const [reasonlist, setReasonlist] = useState([]);
  const [otherreason, setOtherreason] = useState("");
  const [credential, setCredential] = useState("");

  const rarray = [];
  const [otherid, setOtherid] = useState(false);

  const [orderid, setOrderid] = useState("");
  const [paymentdate, setPaymentdate] = useState("");
  const [statusp, setStatusp] = useState("");

  const getPassword = localStorage.getItem("password");
  const { id } = useParams();
  const [appr, setShow1] = useState(false);
  const [rej, setShow3] = useState(false);
  const [block, setShow4] = useState(false);
  const [activate, setShow5] = useState(false);
  const [scredential, setShow7] = useState(false);
  //selectedvalue
  const [selectedValue, setSelectedValue] = useState([]);
  const [rejvalue, setRejValue] = useState([]);

  const [cqual, setCQual] = useState([]);
  const [cpath, setCPath] = useState("");

  const [cspecific, setCSpecific] = useState("");
  const [cplace, setCPlace] = useState("");
  const [caddress, setCAddress] = useState("");

  const [smale, setSMale] = useState("");
  const [sfemale, setSFemale] = useState("");
  const [jmale, setJMale] = useState("");
  const [jfemale, setJFemale] = useState("");

  const [dmale, setDMale] = useState("");
  const [umale, setUMale] = useState("");
  const [nmale, setNMale] = useState("");
  const [imale, setIMale] = useState("");
  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/user.png");
    ev.target.onerror = null;
  }

  const [file, setFile] = useState("");
  const [show, setShow] = useState(false);
  function handleshow(img) {
    setFile("");
    setFile(img);
    setShow(true);
  }

  function handleclose() {
    setShow(false);
  }

  // handle onChange event of the dropdown
  const handleChange = (e) => {
    setRejValue(e);
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
    for (let i = 0; i < e.length; i++) {
      if (e[i].value === 6) {
        setOtherid(true);
      } else {
        setOtherid(false);
      }
    }
  };

  const navigate = useNavigate();

  function setAShow() {
    setErrMsg("");
    setShow1(true);
  }

  function setAClose() {
    setErrMsg("");
    setShow1(false);
  }

  function setRShow() {
    setErrMsg("");
    setShow3(true);
  }

  function setRClose() {
    setErrMsg("");
    setShow3(false);
  }
  function setBShow() {
    setErrMsg("");
    setShow4(true);
  }

  function setBClose() {
    setErrMsg("");
    setShow4(false);
  }
  function setActiveShow() {
    setErrMsg("");
    setShow5(true);
  }

  function setActiveClose() {
    setErrMsg("");
    setShow5(false);
  }

  function setSendCredentialactive() {
    sendcredentials(id).then((response) => {
      if (response.status === true) {
        setCredential(response.message);
        setShow7(true);
      }
    });
  }

  function setSendCredentialclose() {
    setShow7(false);
  }

  function Approve(e) {
    e.preventDefault();
    setErrMsg("");
    if (passApprove !== "") {
      if (getPassword === passApprove) {
        const tempdata = new FormData();
        tempdata.append("user_id", id);
        coachapprove(tempdata).then((response) => {
          if (response.status === true) {
            navigate("/superadmin/approvedcoaches/");
          }
        });
      } else {
        setErrMsg("Password Doesn't match!");
      }
    } else {
      setErrMsg("Please enter you password");
    }
  }

  function Reject(e) {
    e.preventDefault();
    setErrMsg("");

    if (passApprove !== "") {
      if (getPassword === passApprove) {
        let holdrvalue = "";
        if (selectedValue.length !== 0) {
          for (let i = 0; i < selectedValue.length; i++) {
            if (holdrvalue === "") {
              holdrvalue = selectedValue[i];
            } else {
              holdrvalue = holdrvalue + "," + selectedValue[i];
            }
          }
        }
        const tempdata = {
          user_id: id,
          reasonsid: holdrvalue + "",
          remarks: otherreason,
        };
        coachreject(tempdata).then((response) => {
          if (response.status === true) {
            navigate("/superadmin/rejectedcoaches/");
          }
        });
      } else {
        setErrMsg("Password Doesn't match!");
      }
    } else {
      setErrMsg("Please enter you password");
    }
  }
  function Block(e) {
    e.preventDefault();
    setErrMsg("");

    if (passApprove !== "") {
      if (getPassword === passApprove) {
        let holdrvalue = "";
        if (selectedValue.length !== 0) {
          for (let i = 0; i < selectedValue.length; i++) {
            if (holdrvalue === "") {
              holdrvalue = selectedValue[i];
            } else {
              holdrvalue = holdrvalue + "," + selectedValue[i];
            }
          }
        }
        const tempdata = {
          user_id: id,
          reasonsid: holdrvalue + "",
          remarks: otherreason,
        };
        coachblock(tempdata).then((response) => {
          if (response.status === true) {
            setBClose();
            navigate("/superadmin/blockedcoaches/");
          }
        });
      } else {
        setErrMsg("Password Doesn't match!");
      }
    } else {
      setErrMsg("Please enter you password");
    }
  }

  function Active(e) {
    e.preventDefault();
    setErrMsg("");

    if (passApprove !== "") {
      if (getPassword === passApprove) {
        let holdrvalue = "";
        if (selectedValue.length !== 0) {
          for (let i = 0; i < selectedValue.length; i++) {
            if (holdrvalue === "") {
              holdrvalue = selectedValue[i];
            } else {
              holdrvalue = holdrvalue + "," + selectedValue[i];
            }
          }
        }
        const tempdata = {
          user_id: id,
          reasonsid: holdrvalue + "",
          remarks: otherreason,
        };
        activeplayer(tempdata).then((response) => {
          if (response.status === true) {
            navigate("/superadmin/approvedcoaches/");
          }
        });
      } else {
        setErrMsg("Password Doesn't match!");
      }
    } else {
      setErrMsg("Please enter you password");
    }
  }

  const [rejcount, setRejectCnt] = useState(0);
  const [pendoccount, setPenDoc] = useState(0);
  const [reaview, setReasonView] = useState([]);

  const [subrole, setSubRole] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("SubRoleid") === "1") {
      setSubRole(false);
    } else if (localStorage.getItem("SubRoleid") === "2") {
      setSubRole(false);
    } else {
      setSubRole(true);
    }
    const url = window.location.href;
    const last = url.split("/#/");
    const link = last[1].split("/");
    const eid = link[1];
    coachdetails(id).then((response) => {
      if (response.status === true) {
        setFirst(response.data.details.first_name);
        setLast(response.data.details.last_name);
        setDOB(
          moment(response.data.details.date_of_birth).format("DD-MM-YYYY")
        );
        if (response.data.details.created_at !== null) {
          setDOR(moment(response.data.details.created_at).format("DD-MM-YYYY"));
        }
        setGender(response.data.details.gender);
        setEmail(response.data.details.email_id);
        setMobile(response.data.details.mobile_no);
        setCommAddr(response.data.details.communication_address_one);
        setCommaddr2(response.data.details.communication_address_two);
        setCommDistname(response.data.details.communication_district);

        for (let i = 0; i < response.data.comm_state.length; i++) {
          if (
            response.data.details.communication_state ===
            response.data.comm_state[i].stateuid
          ) {
            setCommState(response.data.comm_state[i].statename);
          }
        }
        setCommPincode(response.data.details.communication_zipcode);
        setAddr(response.data.details.permenant_address_one);
        setAddr2(response.data.details.permenant_address_two);
        setDistname(response.data.details.permenant_district);
        setPincode(response.data.details.permenant_zipcode);
        setState(response.data.details.statename);
        setBlood(response.data.details.blood_group);
        setAadhar(response.data.details.aadhaar_no);
        setStatus(response.data.details.status);
        setOrderid(response.data.details.order_id);

        if (response.data.details.paymentdatetime !== null) {
          var mdate = response.data.details.paymentdatetime.split(" ");
          setPaymentdate(moment(mdate[0]).format("DD-MM-YYYY"));
        }
        setStatusp(response.data.details.payment_status);
        setPhotoFile(
          response.data.photo_path + response.data.details.photograph
        );
        setIDFile(
          response.data.id_proof_path + response.data.details.dob_proof
        );
        setAddrFile(
          response.data.address_proof_path + response.data.details.address_proof
        );

        setUid(response.data.details.username);
        setRejectCnt(response.data.details.rejected_count);
        setPenDoc(response.data.details.improper_docs);
        if (response.data.coach_other_details !== null) {
          setOccu(response.data.coach_other_details.occupation);
          setCSpecific(
            response.data.coach_other_details.coaching_specification
          );
          setCPlace(response.data.coach_other_details.place_of_coacing);
          setCAddress(response.data.coach_other_details.address_of_coaching);
          setSMale(response.data.coach_other_details.senior_male_count);
          setSFemale(response.data.coach_other_details.senior_female_count);
          setJMale(response.data.coach_other_details.junior_male_count);
          setJFemale(response.data.coach_other_details.junior_female_count);
          setDMale(response.data.coach_other_details.school_or_district_count);
          setUMale(response.data.coach_other_details.university_or_state_count);
          setNMale(response.data.coach_other_details.national_level_count);
          setIMale(response.data.coach_other_details.international_level_count);
        }

        if (response.data.coach_certificates.length !== 0) {
          setCQual(response.data.coach_certificates);
          setCPath(response.data.certificate_path);
        }
      }
    });

    holdpop().then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            rarray.push({
              value: response.data[i].id,
              label: response.data[i].reason_name,
            });
          }
        }
        setReasonlist(rarray);
      }
    });

    rejectedreasons(id, 2).then((response) => {
      if (response.status === true) {
        setReasonView(response.data);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Coach Profile</h2>
        </div>
      </div>{" "}
      <Card className=" custom-card overflow-hidden">
        <Card.Body>
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">First Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{first}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Last Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{last}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">DOB</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{dob}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Gender</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{gender}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Email</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{email}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Blood Group</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{blood}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Mobile</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{mobile}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Aadhar Number</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{aadhar}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Occupation</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{occu}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">AFI-UID</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{uid}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Status</b>
                        </div>
                        <div className="col-md-8">
                          {status === 0 && (
                            <span
                              className="text-inverse"
                              style={{ color: "#17a2b8", fontWeight: "600" }}
                            >
                              PENDING
                            </span>
                          )}
                          {status === 1 && (
                            <span
                              className="text-inverse"
                              style={{ color: "green", fontWeight: "600" }}
                            >
                              APPROVED
                            </span>
                          )}
                          {status === 2 && (
                            <span
                              className="text-inverse"
                              style={{ color: "red", fontWeight: "600" }}
                            >
                              REJECTED
                            </span>
                          )}
                          {status === 3 && (
                            <span
                              className="text-inverse"
                              style={{ color: "red", fontWeight: "600" }}
                            >
                              BLOCKED
                            </span>
                          )}
                          {status === 4 && (
                            <span
                              className="text-inverse"
                              style={{ color: "#17a2b8", fontWeight: "600" }}
                            >
                              HOLD
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">DOR</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{dor}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-6">
                    <h5>PERMANENT ADDRESS</h5>
                  </div>
                  <div className="col-md-6">
                    <h5>COMMUNICATION ADDRESS</h5>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 1</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{addr}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 1</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commaddr}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 2</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{addr2}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 2</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commaddr2}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">District</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{pdistname}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">District</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commdistname}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">State</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{state}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">State</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commstate}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Pincode</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{ppin}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Pincode</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commpin}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <hr />
                <div className="row">
                  <div className="col-md-12">
                    <h5>COACH QUALIFICATIONS</h5>
                  </div>
                  <div className="col-md-12">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Coaching Specialization (if any)
                          </b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{cspecific}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {cqual.map((data, i) => {
                  return (
                    <div className="row mt-2" key={i}>
                      <div className="col-md-4">
                        <div className="profile-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Level</b>
                            </div>
                            <div className="col-md-8">
                              <span className="text-inverse">{data.level}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="profile-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Year of Passing</b>
                            </div>
                            <div className="col-md-8">
                              <span className="text-inverse">
                                {data.year_of_passing}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Certificate</b>
                          </div>
                          <div className="col-md-8">
                            <img
                              className="profile_img"
                              src={cpath + data.certificate_path}
                              alt="photo"
                              onClick={() =>
                                handleshow(cpath + data.certificate_path)
                              }
                              onError={(e) => errorhandle(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <hr />
                <div className="row">
                  <div className="col-md-12">
                    <h5>COACHING DETAILS</h5>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Place of Coaching</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{cplace}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Address of Coaching Center
                          </b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{caddress}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <h5>Total number of athletes present under you</h5>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Senior Male</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{smale}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Senior Female</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{sfemale}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Junior Male</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{jmale}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Junior Female</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{jfemale}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">School/ District</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{dmale}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">University/ State</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{umale}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">National level</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{nmale}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">International level</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{imale}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                <div className="row">
                  <div className="col-md-12">
                    <h5>PAYMENT DETAILS</h5>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Order ID</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{orderid}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Payment Date</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{paymentdate}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Status</b>
                        </div>
                        <div className="col-md-8">
                          {statusp === 1 && (
                            <span className="text-inverse">Paid</span>
                          )}
                          {statusp !== 1 && (
                            <span className="text-inverse">Not Paid</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Photograph</b>
                      </div>
                      <div className="col-md-8">
                        <img
                          className="profile_img"
                          src={formphoto}
                          alt="photo"
                          onClick={() => handleshow(formphoto)}
                          onError={(e) => errorhandle(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">ID Proof</b>
                      </div>
                      <div className="col-md-8">
                        <img
                          className="profile_img"
                          src={formid}
                          alt="photo"
                          onClick={() => handleshow(formid)}
                          onError={(e) => errorhandle(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Address Proof</b>
                      </div>
                      <div className="col-md-8">
                        <img
                          className="profile_img"
                          src={formaddr}
                          alt="photo"
                          onClick={() => handleshow(formaddr)}
                          onError={(e) => errorhandle(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                {status === 2 && rejcount !== 0 && (
                  <div>
                    <h5>REJECTED REASONS</h5>
                    <ul>
                      {reaview.map((data, i) => {
                        return <li key={i}>{data.reasons}</li>;
                      })}
                    </ul>
                  </div>
                )}

                {/* Pending */}
                {status === 0 && (
                  <div className="grp-btns text-center">
                    <button
                      className="btn-f btn-secondary mt-2"
                      onClick={() => navigate(-1)}
                    >
                      back
                    </button>
                    <button
                      className="btn-f btn-success mt-2"
                      onClick={setAShow}
                    >
                      approve profile
                    </button>
                    <button
                      className="btn-f btn-danger mt-2"
                      onClick={setRShow}
                    >
                      reject profile
                    </button>

                    <button
                      className="btn-f btn-print mt-2"
                      onClick={() => window.print()}
                    >
                      print
                    </button>
                  </div>
                )}
                {/* Approved */}
                {status === 1 && (
                  <div className="grp-btns text-center">
                    <button
                      className="btn-f btn-secondary mt-2"
                      onClick={() => navigate(-1)}
                      // onClick={() =>
                      //   navigate("/superadmin/approvedathletes")
                      // }
                    >
                      back
                    </button>
                    <button
                      className="btn-f btn-danger mt-2"
                      onClick={setBShow}
                    >
                      Block profile
                    </button>
                    {!subrole && (
                      <button
                        className="btn-f btn-success mt-2"
                        onClick={() =>
                          navigate("/superadmin/editcoaches/" + id)
                        }
                      >
                        Edit
                      </button>
                    )}

                    <button
                      className="btn-f btn-info mt-2"
                      onClick={setSendCredentialactive}
                    >
                      send credentials
                    </button>
                    <button
                      className="btn-f btn-print mt-2"
                      onClick={() => window.print()}
                    >
                      print
                    </button>
                  </div>
                )}
                {/* //Reject Athlets */}
                {status === 2 && rejcount === 0 && (
                  <div className="grp-btns text-center">
                    <button
                      className="btn-f btn-secondary mt-2"
                      onClick={() => navigate(-1)}
                      // onClick={() =>
                      //   navigate("/superadmin/rejectedathletes")
                      // }
                    >
                      back
                    </button>

                    {!subrole && (
                      <button
                        className="btn-f btn-success mt-2"
                        onClick={() =>
                          navigate("/superadmin/editathlete/" + id)
                        }
                      >
                        Edit
                      </button>
                    )}
                    <button
                      className="btn-f btn-success mt-2"
                      onClick={setAShow}
                    >
                      Approve Profile
                    </button>

                    <button
                      className="btn-f btn-print mt-2"
                      onClick={() => window.print()}
                    >
                      print
                    </button>
                  </div>
                )}

                {/* Blocked */}
                {status === 3 && (
                  <div className="grp-btns text-center">
                    <button
                      className="btn-f btn-secondary mt-2"
                      onClick={() => navigate(-1)}
                    >
                      back
                    </button>
                    <button
                      className="btn-f btn-success mt-2"
                      onClick={setActiveShow}
                    >
                      active player
                    </button>
                    <button
                      className="btn-f btn-print mt-2"
                      onClick={() => window.print()}
                    >
                      print
                    </button>
                  </div>
                )}

                {/* Document updated - Rejected */}
                {status === 2 && rejcount !== 0 && (
                  <div className="grp-btns text-center">
                    <button
                      className="btn-f btn-secondary mt-2"
                      onClick={() => navigate(-1)}
                    >
                      back
                    </button>
                    <button
                      className="btn-f btn-success mt-2"
                      onClick={setAShow}
                    >
                      approve profile
                    </button>
                    <button
                      className="btn-f btn-danger mt-2"
                      onClick={setRShow}
                    >
                      reject profile
                    </button>
                  </div>
                )}

                <br />
                <hr></hr>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Modal show={show}>
        <Modal.Body>
          <img src={file} alt="Image" onError={(e) => errorhandle(e)} />
        </Modal.Body>
        <Modal.Footer>
          <a
            className="btn btn-next btn-success"
            style={{ float: "right" }}
            href={file}
            download
            target="_blank"
          >
            Download
          </a>
          <a
            className="btn btn-next btn-secondary"
            style={{ float: "right" }}
            onClick={handleclose}
          >
            Close
          </a>
        </Modal.Footer>
      </Modal>
      <Modal show={appr} size="large">
        <Modal.Header>
          <h6 className="rep-head">APPROVE PROFILE</h6>
          <i
            className="fa fa-window-close fa-lg"
            onClick={setAClose}
            aria-hidden="true"
          ></i>
        </Modal.Header>
        <Modal.Body className="mod-body">
          <h6 className="popup-modal">
            Approve the membership application of {first} and allocate the
            Registration Number ?
          </h6>
          <div>
            <div className="rep__container">
              <p>Your password to proceed:</p>
              <div className="col-lg">
                <div className="col-lg">
                  <Form.Control
                    placeholder="Password.."
                    type="password"
                    value={passApprove}
                    onChange={(e) => {
                      setPassApprove(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            {Errmsg !== "" && (
              <span className="errortxt text-danger">{Errmsg}</span>
            )}

            <div className="approve-cen">
              <button className="btn-f btn-success" onClick={Approve}>
                Approve
              </button>
            </div>
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={setAClose} className="btn-f btn-danger">
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={rej} size="large">
        <Modal.Header>
          <h6 className="rep-head">REJECT PROFILE</h6>
          <i
            className="fa fa-window-close fa-lg"
            onClick={setRClose}
            aria-hidden="true"
          ></i>{" "}
        </Modal.Header>
        <Modal.Body className="mod-body">
          <h6 className="popup-modal">
            Reject the membership application of {first} ?
          </h6>
          <div>
            <div>
              <Select
                className="dropdown"
                placeholder="Select Option"
                value={reasonlist.filter((obj) =>
                  selectedValue.includes(obj.value)
                )} // set selected values
                options={reasonlist} // set list of the data
                onChange={handleChange} // assign onChange function
                isMulti
                isClearable
              />
            </div>
            {otherid && (
              <div className="col-lg textarea-reason">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter the reason"
                  onChange={(e) => {
                    setOtherreason(e.target.value);
                  }}
                ></Form.Control>
              </div>
            )}
            <div className="rep__container">
              <p>Your password to proceed:</p>
              <div className="col-lg">
                <div className="col-lg">
                  <Form.Control
                    placeholder="Password.."
                    type="password"
                    value={passApprove}
                    onChange={(e) => {
                      setPassApprove(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            {Errmsg !== "" && (
              <span className="errortxt text-danger">{Errmsg}</span>
            )}

            <div className="approve-cen">
              <button className="btn-f btn-danger" onClick={Reject}>
                Reject Profile
              </button>
            </div>
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={setRClose} className="btn-f btn-danger">
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={block} size="large">
        <Modal.Header>
          <h6 className="rep-head">BLOCK PROFILE</h6>
          <i
            className="fa fa-window-close fa-lg"
            onClick={setBClose}
            aria-hidden="true"
          ></i>{" "}
        </Modal.Header>
        <Modal.Body className="mod-body">
          <h6 className="popup-modal">
            Block the membership application of {first} ?
          </h6>
          <div>
            <div>
              <Select
                className="dropdown"
                placeholder="Select Option"
                value={reasonlist.filter((obj) =>
                  selectedValue.includes(obj.value)
                )} // set selected values
                options={reasonlist} // set list of the data
                onChange={handleChange} // assign onChange function
                isMulti
                isClearable
              />
            </div>
            {otherid && (
              <div className="col-lg textarea-reason">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter the reason"
                  onChange={(e) => {
                    setOtherreason(e.target.value);
                  }}
                ></Form.Control>
              </div>
            )}
            <div className="rep__container">
              <p>Your password to proceed:</p>
              <div className="col-lg">
                <div className="col-lg">
                  <Form.Control
                    placeholder="Password.."
                    type="password"
                    value={passApprove}
                    onChange={(e) => {
                      setPassApprove(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            {Errmsg !== "" && (
              <span className="errortxt text-danger">{Errmsg}</span>
            )}

            <div className="approve-cen">
              <button className="btn-f btn-danger" onClick={Block}>
                Block Profile
              </button>
            </div>
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={setBClose} className="btn-f btn-danger">
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={activate} size="large">
        <Modal.Header>
          <h6 className="rep-head">ACTIVE PROFILE</h6>
          <i
            className="fa fa-window-close fa-lg"
            onClick={setActiveClose}
            aria-hidden="true"
          ></i>{" "}
        </Modal.Header>
        <Modal.Body className="mod-body">
          <h6 className="popup-modal">
            Active the membership application of {first} ?
          </h6>
          <div>
            <div>
              <Select
                className="dropdown"
                placeholder="Select Option"
                value={reasonlist.filter((obj) =>
                  selectedValue.includes(obj.value)
                )} // set selected values
                options={reasonlist} // set list of the data
                onChange={handleChange} // assign onChange function
                isMulti
                isClearable
              />
            </div>
            {otherid && (
              <div className="col-lg textarea-reason">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter the reason"
                  onChange={(e) => {
                    setOtherreason(e.target.value);
                  }}
                ></Form.Control>
              </div>
            )}
            <div className="rep__container">
              <p>Your password to proceed:</p>
              <div className="col-lg">
                <div className="col-lg">
                  <Form.Control
                    placeholder="Password.."
                    type="password"
                    value={passApprove}
                    onChange={(e) => {
                      setPassApprove(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            {Errmsg !== "" && (
              <span className="errortxt text-danger">{Errmsg}</span>
            )}

            <div className="approve-cen">
              <button className="btn-f btn-info" onClick={Active}>
                Active Profile
              </button>
            </div>
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={setActiveClose} className="btn-f btn-danger">
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={scredential} size="large">
        <Modal.Header>
          <h6 className="rep-head">ATHLETE CREDENTIAL</h6>

          <i
            className="fa fa-window-close fa-lg"
            onClick={setSendCredentialclose}
            aria-hidden="true"
          ></i>
        </Modal.Header>
        <Modal.Body className="mod-body">
          <div>
            <div className="rep__container">
              <p>{credential}</p>
            </div>
            {Errmsg !== "" && (
              <span className="errortxt text-danger">{Errmsg}</span>
            )}
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={setSendCredentialclose} className="btn-f btn-danger">
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ViewCoach;
