import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import {
  adddistrictpaymentstatus,
  adddistrictsummarydetail,
  districtconpdf,
  districtpaymentstatus,
  districtsummarydetail,
  meetdetails,
} from "../../../services/meetservice";
import moment from "moment";
import imageUrl from "../../../ImageUrl";
import fileString from "../../../assets/language/filepath.json";

const Confirmation = () => {
  const { id } = useParams();
  const [tname, setTName] = useState("");
  const [tdate, setTDate] = useState("");
  const [tcode, setTCode] = useState("");
  const [cir, setCircular] = useState("");
  const [cir2, setCircular2] = useState("");
  const [cir3, setCircular3] = useState("");
  const [cir4, setCircular4] = useState("");
  const [cir5, setCircular5] = useState("");

  const [athlete, setAthlete] = useState([]);
  const navigate = useNavigate();
  const [urllink, setUrlLink] = useState("");

  useState(() => {
    const url = window.location.href;
    const data = url.split("/#/");
    const link = data[1].split("/");
    setUrlLink(link[1]);

    const temp = new FormData();
    temp.append("current_meet_id", id);
    if (link[1] === "additionalentrylist") {
      adddistrictpaymentstatus(temp).then((response) => {
        if (response.status === true) {
          if (response.data.Payment_Status !== "Paid") {
            navigate("/district/additionalviewsummary/" + id);
          }
        }
      });
    }else{
      districtpaymentstatus(temp).then((response) => {
        if (response.status === true) {
          if (response.data.Payment_Status !== "Paid") {
            navigate("/district/viewsummary/" + id);
          }
        }
      });
    }

    meetdetails(id).then((response) => {
      if (response.status === true) {
        setTName(response.data.tournament.meet_name);
        setTDate(
          moment(response.data.tournament.start_date).format("DD-MM-YYYY") +
            " to " +
            moment(response.data.tournament.end_date).format("DD-MM-YYYY")
        );
        setTCode(response.data.tournament.meet_code);
        if (response.data.tournament.circular_file_1 !== null) {
          if (response.data.tournament.circular_file_1 !== "-") {
            setCircular(response.data.tournament.circular_file_1);
          }
        }
        if (response.data.tournament.circular_file_2 !== null) {
          if (response.data.tournament.circular_file_2 !== "-") {
            setCircular2(response.data.tournament.circular_file_2);
          }
        }

        if (response.data.tournament.circular_file_3 !== null) {
          if (response.data.tournament.circular_file_3 !== "-") {
            setCircular3(response.data.tournament.circular_file_3);
          }
        }

        if (response.data.tournament.circular_file_4 !== null) {
          if (response.data.tournament.circular_file_4 !== "-") {
            setCircular4(response.data.tournament.circular_file_4);
          }
        }

        if (response.data.tournament.circular_file_5 !== null) {
          if (response.data.tournament.circular_file_5 !== "-") {
            setCircular5(response.data.tournament.circular_file_5);
          }
        }
      }
      if (link[1] === "additionalconfirmation") {
        adddistrictsummarydetail(id, localStorage.getItem("Userid")).then(
          (response) => {
            if (response.status === true) {
              setAthlete(response.data.added_athletes);
            }
          }
        );
      }else{
        districtsummarydetail(id, localStorage.getItem("Userid")).then(
          (response) => {
            if (response.status === true) {
              setAthlete(response.data.added_athletes);
            }
          }
        );
      }
      
    });
  }, []);

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/placeholder.png");
    ev.target.onerror = null;
  }

  function downloadpdf() {
    districtconpdf(id, localStorage.getItem("Userid")).then((response) => {
      if (response.status === true) {
        window.open(response.data, "_blank");
      }
    });
  }
  return (
    <div>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">{tname}</h2>
          <Breadcrumb>
            <Breadcrumb.Item href="#">
              Event Code : <span>{tcode}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              Date : <span>{tdate}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            <a
              href={imageUrl + fileString.tournamentfiles + cir}
              className="btn btn-secondary me-2"
              target={"_blank"}
            >
              <i className="fe fe-download-cloud me-2"></i> Download Circular
            </a>
            {cir2 !== "" && (
              <a
                href={imageUrl + fileString.tournamentfiles + cir2}
                className="btn btn-secondary me-2"
                target={"_blank"}
              >
                <i className="fe fe-download-cloud me-2"></i> Download Circular
              </a>
            )}
            {cir3 !== "" && (
              <a
                href={imageUrl + fileString.tournamentfiles + cir3}
                className="btn btn-secondary me-2"
                target={"_blank"}
              >
                <i className="fe fe-download-cloud me-2"></i> Download Circular
              </a>
            )}
            {cir4 !== "" && (
              <a
                href={imageUrl + fileString.tournamentfiles + cir4}
                className="btn btn-secondary me-2"
                target={"_blank"}
              >
                <i className="fe fe-download-cloud me-2"></i> Download Circular
              </a>
            )}
            {cir5 !== "" && (
              <a
                href={imageUrl + fileString.tournamentfiles + cir5}
                className="btn btn-secondary me-2"
                target={"_blank"}
              >
                <i className="fe fe-download-cloud me-2"></i> Download Circular
              </a>
            )}
            <Link className="btn btn-danger mr-2" to={"/district/dashboard"}>
              <i className="fe fe-corner-up-left me-2"></i> Back
            </Link>
          </div>
        </div>
      </div>

      <Row className="mx-0">
        <Col md={12} className="px-0">
          <Card className="pt-0  custom-card pt-2 bg-background2 card pb-2 border-0 overflow-hidden text-center">
            <div className="header-text mb-0">
              <Container fluid className="p-3 text-center">
                <div className="text-center text-white background-text ">
                  <h1 className="mb-3 tx-46 font-weight-semibold text-primary">
                    Thank you for submitting all the entries !
                  </h1>
                  <p className="tx-20 mb-3 text-success">
                    All of the Entries were successfully submitted by you.{" "}
                    <br></br>Download the entry registration{" "}
                    <b>confirmation PDF</b> by clicking the button below..
                  </p>
                </div>
                <Row>
                  <Col xl={12} lg={12} md={12} className="d-block mx-auto">
                    <div className="text-center background-text">
                      <a
                        onClick={downloadpdf}
                        className="btn btn-danger ps-6 pe-6 pt-2 pb-2 mx-auto mt-1"
                      >
                        <i className="fe fe-download-cloud me-2"></i> Download
                        Confirmation PDF
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Card>
        </Col>
      </Row>

      <Row className="row-sm mb-3">
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className="pt-3 pb-1 d-block">
            <h3 className="tx-24">Selected Athlete Entries</h3>
          </div>
        </Col>
        {athlete.map((data, i) => {
          return (
            <Col xl={3} lg={3} md={6} sm={6} key={i}>
              <Card className="custom-card our-team">
                <Card.Body className="px-3 pt-3 pb-0 bg-cusimg">
                  <div className="picture avatar-lg online text-center">
                    <img
                      alt="avatar"
                      className=""
                      src={imageUrl + fileString.athletephoto + data.photograph}
                      onError={(e) => errorhandle(e)}
                    />
                  </div>
                  <div className="text-center mt-2">
                    <a>
                      {data.middle_name !== null && (
                        <h5 className="pro-user-username text-dark mt-2 mb-0">
                          {data.first_name} {data.middle_name} {data.last_name}
                        </h5>
                      )}
                      {data.middle_name === null && (
                        <h5 className="pro-user-username text-dark mt-2 mb-0">
                          {data.first_name} {data.last_name}
                        </h5>
                      )}
                    </a>
                    <p className="pro-user-desc text-danger mb-2">
                      ({data.username})
                    </p>
                    <Card.Body className="px-1 py-0 athlelst">
                      <h5 className="list-content-label tx-dark tx-medium mg-b-10 tx-18">
                        <span>Gender </span> <b>{data.gender}</b>
                      </h5>
                      <h5 className="list-content-label tx-dark tx-medium mg-b-10 tx-18">
                        <span>DOB </span>{" "}
                        <b>{moment(data.date_of_birth).format("DD-MM-YYYY")}</b>
                      </h5>
                      <h5 className="list-content-label border-bottom-none tx-dark tx-medium mg-b-10 tx-18">
                        <span>Category </span> <b>{data.age_category}</b>
                      </h5>
                      <h5 className="list-content-label tx-dark tx-medium mg-b-10 tx-18 border-top">
                        <span>Events </span> <b>{data.events}</b>
                      </h5>
                    </Card.Body>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Confirmation;
