import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  allcoachlist,
  approvecoachlist,
  blockcoachlist,
  pendcoachlist,
  rejectcoachlist,
} from "../../../services/superadmin";
const AllCoach = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const gridRef = useRef();
  var arr = [];
  const [urllink, setUrllink] = useState("");

  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    const path = link[1];
    setUrllink(path);
    setFilterProduct([]);
    if (path === "allcoaches") {
      allcoachlist(localStorage.getItem("Userid")).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    } else if (path === "pendingcoaches") {
      pendcoachlist(localStorage.getItem("Userid")).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    } else if (path === "approvedcoaches") {
      approvecoachlist(localStorage.getItem("Userid")).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    } else if (path === "blockedcoaches") {
      blockcoachlist(localStorage.getItem("Userid")).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    } else if (path === "rejectedcoaches") {
      rejectcoachlist(localStorage.getItem("Userid")).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    }
  }, [window.location.href]);

  const columnDefs = [
    {
      headerName: "Reg No",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "First Name",
      width: 170,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Last Name",
      width: 170,
      field: "last_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "D.O.B",
      width: 150,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },

    {
      headerName: "State",
      width: 150,
      field: "statename",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      width: 150,
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      width: 150,
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOR",
      width: 150,
      field: "created_at",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Order Id",
      width: 300,
      field: "order_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Actions",
      width: 100,
      field: "user_id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <Link
            to={"/superadmin/viewcoaches/" + params.value}
            className="btn-sm btn-info"
          >
            <i className="fa fa-eye fa-lg"></i>
          </Link>
        );
      },
    },
  ];

  const columnPDefs = [
    {
      headerName: "First Name",
      width: 170,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Last Name",
      width: 170,
      field: "last_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "D.O.B",
      width: 150,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },

    {
      headerName: "State",
      width: 150,
      field: "statename",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      width: 150,
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      width: 150,
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOR",
      width: 150,
      field: "created_at",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Order Id",
      width: 300,
      field: "order_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      width: 150,
      field: "status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Actions",
      width: 100,
      field: "user_id",
      filter: false,
      resizable: true,
      cellRenderer: function (params) {
        return (
          <Link
            to={"/superadmin/viewcoaches/" + params.value}
            className="btn-sm btn-info"
          >
            <i className="fa fa-eye fa-lg"></i>
          </Link>
        );
      },
    },
  ];

  const navigate = useNavigate();

  const onexport = useCallback(() => {
    var excelParams = {
      allColumns: false,
      columnKeys: [
        "username",
        "first_name",
        "last_name",
        "gender",
        "date_of_birth",
        "statename",
        "mobile_no",
        "email_id",
        "created_at",
        "order_id",
      ],

      fileName: "All Coach List.csv",
    };

    gridRef.current.api.exportDataAsCsv(excelParams);
  }, []);

  return (
    <Fragment>
      <div className="page-header reported-header">
        {urllink === "allcoaches" && (
          <h2 className="main-content-title tx-24 mg-b-5">All Coaches</h2>
        )}
        {urllink === "pendingcoaches" && (
          <h2 className="main-content-title tx-24 mg-b-5">Pending Coaches</h2>
        )}
        {urllink === "approvedcoaches" && (
          <h2 className="main-content-title tx-24 mg-b-5">Approved Coaches</h2>
        )}
        {urllink === "blockedcoaches" && (
          <h2 className="main-content-title tx-24 mg-b-5">Blocked Coaches</h2>
        )}
        {urllink === "rejectedcoaches" && (
          <h2 className="main-content-title tx-24 mg-b-5">Rejected Coaches</h2>
        )}
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              {urllink === "allcoaches" && (
                <div className="d-flex justify-content-end">
                
                  <div>
                    <button
                      type="button"
                      onClick={onexport}
                      className="btn btn-success"
                    >
                      Export
                    </button>
                  </div>
                </div>
              )}
              <div className="responsive">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  {urllink === "pendingcoaches" && (
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={columnPDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                  {urllink !== "pendingcoaches" && (
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AllCoach;
