import moment from "moment";
import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  adddistrictpaymentstatus,
  adddistrictsummarydetail,
  districtpaymentstatus,
  districtsummarydetail,
  meetdetails,
} from "../../../services/meetservice";
import imageUrl from "../../../ImageUrl";
import { AgGridReact } from "ag-grid-react";
import fileString from "../../../assets/language/filepath.json";

const Districtentry = () => {
  const { id } = useParams();
  const [tname, setTName] = useState("");
  const [tloc, setTLoc] = useState("");

  const [tdate, setTDate] = useState("");
  const [odate, setODate] = useState("");
  const [ldate, setLDate] = useState("");
  const [tcode, setTCode] = useState("");
  const [cir, setCircular] = useState("");
  const [cir2, setCircular2] = useState("");
  const [cir3, setCircular3] = useState("");
  const [cir4, setCircular4] = useState("");
  const [cir5, setCircular5] = useState("");

  const [logo, setLogo] = useState("");

  // const [category, setCategory] = useState("");
  const [catamt, setCatAmt] = useState([]);
  const [catlist, setCatList] = useState([]);
  // const [maxcat, setMaxCat] = useState([]);

  const [filterProduct, setFilterProduct] = useState([]);

  const columnDefs = [
    {
      headerName: "Photo",
      width: 110,
      resizable: true,
      field: "photograph",
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <img
            style={{ width: "50px", height: "50px" }}
            src={imageUrl + fileString.athletephoto + params.data.photograph}
            onError={(e) => errorhandle(e)}
          />
        );
      },
    },
    {
      headerName: "AFI UID",
      width: 170,
      field: "username",
      sortable: true,
      filter: true,
      resizable: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 210,
      field: "first_name",
      sortable: true,
      filter: true,
      resizable: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      valueGetter: function sumField(params) {
        if (params.data.middle_name !== null) {
          return (
            params.data.first_name +
            " " +
            params.data.middle_name +
            " " +
            params.data.last_name
          );
        } else {
          return params.data.first_name + " " + params.data.last_name;
        }
      },
    },
    {
      headerName: "Category",
      width: 150,
      field: "age_category",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event",
      width: 150,
      field: "events",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOB",
      width: 150,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },

      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Gender",
      width: 140,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];
  const navigate = useNavigate();
  const [urllink, setUrlLink] = useState("");
  useEffect(() => {
    const url = window.location.href;
    const data = url.split("/#/");
    const link = data[1].split("/");
    setUrlLink(link[1]);

    const temp = new FormData();
    temp.append("current_meet_id", id);
    if (link[1] === "additionalentrylist") {
      adddistrictpaymentstatus(temp).then((response) => {
        if (response.status === true) {
          if (response.data.Payment_Status === "Paid") {
            navigate("/district/additionalconfirmation/" + id);
          }
        }
      });
    } else {
      districtpaymentstatus(temp).then((response) => {
        if (response.status === true) {
          if (response.data.Payment_Status === "Paid") {
            navigate("/district/confirmation/" + id);
          }
        }
      });
    }

    meetdetails(id).then((response) => {
      if (response.status === true) {
        setTName(response.data.tournament.meet_name);
        setTLoc(response.data.tournament.location);
        setTDate(
          moment(response.data.tournament.start_date).format("DD-MM-YYYY") +
            " - " +
            moment(response.data.tournament.end_date).format("DD-MM-YYYY")
        );
        if (response.data.tournament.entry_opens !== null) {
          setODate(
            moment(response.data.tournament.entry_opens).format("DD-MM-YYYY")
          );
        }

        setLDate(
          moment(response.data.tournament.entry_deadline_date).format(
            "DD-MM-YYYY"
          )
        );
        setTCode(response.data.tournament.meet_code);
        if (response.data.tournament.circular_file_1 !== null) {
          if (response.data.tournament.circular_file_1 !== "-") {
            setCircular(response.data.tournament.circular_file_1);
          }
        }
        if (response.data.tournament.circular_file_2 !== null) {
          if (response.data.tournament.circular_file_2 !== "-") {
            setCircular2(response.data.tournament.circular_file_2);
          }
        }

        if (response.data.tournament.circular_file_3 !== null) {
          if (response.data.tournament.circular_file_3 !== "-") {
            setCircular3(response.data.tournament.circular_file_3);
          }
        }

        if (response.data.tournament.circular_file_4 !== null) {
          if (response.data.tournament.circular_file_4 !== "-") {
            setCircular4(response.data.tournament.circular_file_4);
          }
        }

        if (response.data.tournament.circular_file_5 !== null) {
          if (response.data.tournament.circular_file_5 !== "-") {
            setCircular5(response.data.tournament.circular_file_5);
          }
        }
        setLogo(
          imageUrl + fileString.tournamentfiles + response.data.tournament.logo
        );
        setCatList(response.data.groupedagemaxathlte);
        setCatAmt(response.data.groupedagecategoryonamt);

        // if (response.data.groupedagemaxathlte.length !== 0) {
        //   var cat = "";
        //   var amt = "";
        //   for (let i = 0; i < response.data.groupedagemaxathlte.length; i++) {
        //     if (cat === "") {
        //       cat = response.data.groupedagemaxathlte[i].cate;
        //     } else {
        //       cat = cat + "," + response.data.groupedagemaxathlte[i].cate;
        //     }

        //     if (amt === "") {
        //       amt = response.data.groupedagemaxathlte[i].max_athlete_event;
        //     } else {
        //       amt =
        //         amt +
        //         "," +
        //         response.data.groupedagemaxathlte[i].max_athlete_event;
        //     }
        //   }

        //   setCatList(cat);
        //   setMaxCat(amt);
        // }

        // if (response.data.groupedagecategoryonamt.length !== 0) {
        //   var cat = "";
        //   var amt = "";
        //   for (
        //     let i = 0;
        //     i < response.data.groupedagecategoryonamt.length;
        //     i++
        //   ) {
        //     if (cat === "") {
        //       cat = response.data.groupedagecategoryonamt[i].cate;
        //     } else {
        //       cat = cat + "," + response.data.groupedagecategoryonamt[i].cate;
        //     }

        //     if (amt === "") {
        //       amt = response.data.groupedagecategoryonamt[i].fees;
        //     } else {
        //       amt = amt + "," + response.data.groupedagecategoryonamt[i].fees;
        //     }
        //   }

        //   setCategory(cat);
        //   setCatAmt(amt);
        // }
        if (link[1] === "additionalentrylist") {
          adddistrictsummarydetail(id, localStorage.getItem("Userid")).then(
            (response) => {
              if (response.status === true) {
                setFilterProduct(response.data.added_athletes);
              }
            }
          );
        }else{
          districtsummarydetail(id, localStorage.getItem("Userid")).then(
            (response) => {
              if (response.status === true) {
                setFilterProduct(response.data.added_athletes);
              }
            }
          );
        }
        
      }
    });
  }, []);
  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/placeholder.png");
    ev.target.onerror = null;
  }
  return (
    <div id="entrylistt">
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-28 mg-b-0 text-danger">
            {localStorage.getItem("User")} District
          </h2>
        </div>

        <div className="d-flex mt-2">
          <div className="justify-content-center">
            <a
              href={imageUrl + fileString.tournamentfiles + cir}
              className="btn btn-secondary me-2"
              target={"_blank"}
            >
              <i className="fe fe-eye me-2"></i> View Circular
            </a>
            {cir2 !== "" && (
              <a
                href={imageUrl + fileString.tournamentfiles + cir2}
                className="btn btn-secondary me-2"
                target={"_blank"}
              >
                <i className="fe fe-eye me-2"></i> View Circular
              </a>
            )}

            {cir3 !== "" && (
              <a
                href={imageUrl + fileString.tournamentfiles + cir3}
                className="btn btn-secondary me-2"
                target={"_blank"}
              >
                <i className="fe fe-eye me-2"></i> View Circular
              </a>
            )}

            {cir4 !== "" && (
              <a
                href={imageUrl + fileString.tournamentfiles + cir4}
                className="btn btn-secondary me-2"
                target={"_blank"}
              >
                <i className="fe fe-eye me-2"></i> View Circular
              </a>
            )}
            {cir5 !== "" && (
              <a
                href={imageUrl + fileString.tournamentfiles + cir5}
                className="btn btn-secondary me-2"
                target={"_blank"}
              >
                <i className="fe fe-eye me-2"></i> View Circular
              </a>
            )}
            <Link to="/district/dashboard" className="btn btn-danger me-2">
              <i className="fe fe-corner-up-left me-2"></i> Back
            </Link>
          </div>
        </div>
      </div>

      <Row className="row-sm mx-0">
        <Col xl={2} lg={12} className=" d-none d-xl-block custom-leftnav">
          <div className="main-content-left-components">
            <Card className=" custom-card">
              <Card.Body className="user-card text-center">
                <div className="main-img-user avatar-xl online text-center">
                  <img
                    alt="AFI"
                    className="rounded-circle"
                    src={logo}
                    // src={require("../../../assets/images/logo/logo-small.png")}
                  />
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>

        <Col xl={10} lg={12}>
          <Card className=" custom-card" id="basic">
            <Card.Body>
              <div>
                <h6 className="main-content-label tx-22 mb-2">{tname}</h6>
              </div>
              <div className="table-responsive">
                <Table
                  className="table main-table-reference mt-0 mb-0"
                  id="entrylisttable"
                >
                  <tbody>
                    <tr>
                      <td>
                        <code>Event Date </code>
                      </td>
                      <td>{tdate}</td>
                      <td>
                        <code>Venue</code>
                      </td>
                      <td>{tloc}</td>
                    </tr>
                    <tr>
                      <td>
                        <code>Entry Opens on</code>
                      </td>
                      <td>{odate}</td>
                      <td>
                        <code>Last Date of Entry</code>
                      </td>
                      <td>{ldate}</td>
                    </tr>
                    <tr>
                      <td>
                        <code>Event Code</code>
                      </td>
                      <td>{tcode}</td>
                    </tr>
                  </tbody>
                </Table>

                <Table
                  className="table main-table-reference mt-2 mb-0"
                  id="entrylisttable"
                >
                  <tbody>
                    {catlist.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <code>{data.cate} </code>
                          </td>
                          <td>Max {data.max_athlete_event} Athlete/Event</td>
                        </tr>
                      );
                    })}
                    {catamt.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <code>{data.cate} Fees </code>
                          </td>
                          <td>{data.fees} INR (per athlete)</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

                {/* {catlist.map((data, i) => {
                      return (
                        <div className="row" key={i}>
                          <div className="col-md-4">
                            <code>{data.cate} </code>
                          </div>
                          <div className="col-md-4">Max {data.max_athlete_event} Athlete/Event</div>
                        </div>
                      );
                    })}
                    {catamt.map((data, i) => {
                      return (
                        <tr key={i}>
                         
                          <td>
                            <code>{data.cate} Fees </code>
                          </td>
                          <td>{data.fees} INR (per athlete)</td>
                        </tr>
                      );
                    })} */}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="row-sm mx-0 mt-3">
        <Col lg={12}>
          <Card className=" custom-card" id="basic">
            <Card.Body>
              <div>
                <div className="mb-3 d-flex">
                  {urllink === "additionalentrylist" && (
                    <>
                      <Link
                        to={"/district/additionaladdathlete/" + id}
                        className="btn btn-success btn-sm me-2"
                      >
                        {/* <i className="fe fe-plus me-2"></i>  */}
                        Add Athlete
                      </Link>
                      <Link
                        to={"/district/additionalviewsummary/" + id}
                        className="btn btn-info btn-sm me-2"
                      >
                        {/* <i className="fe fe-eye me-2"></i>  */}
                        View Summary
                      </Link>
                    </>
                  )}
                  {urllink !== "additionalentrylist" && (
                    <>
                      <Link
                        to={"/district/addathlete/" + id}
                        className="btn btn-success btn-sm me-2"
                      >
                        {/* <i className="fe fe-plus me-2"></i>  */}
                        Add Athlete
                      </Link>
                      <Link
                        to={"/district/viewsummary/" + id}
                        className="btn btn-info btn-sm me-2"
                      >
                        {/* <i className="fe fe-eye me-2"></i>  */}
                        View Summary
                      </Link>
                    </>
                  )}
                </div>
              </div>
              <div className="table-responsive">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                  }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Districtentry;
