export const MENUITEMS = [
  {
    Items: [
      {
        path: "/superadmin/dashboard",
        icon: "fa fa-home",
        type: "link",
        active: true,
        selected: true,
        title: "Dashboard",
      },
      {
        title: "Master",
        icon: "fa fa-users",
        type: "sub",
        active: false,
        selected: false,
        children: [
          // {
          //   path: "/master/menulist",
          //   type: "link",
          //   active: false,
          //   selected: false,
          //   title: "Menu Master",
          // },
          {
            path: "/master/rolelist",
            type: "link",
            active: false,
            selected: false,
            title: "Roles",
          },

          {
            path: "/master/statelist",
            type: "link",
            active: false,
            selected: false,
            title: "State",
          },
          {
            path: "/master/districtlist",
            type: "link",
            active: false,
            selected: false,
            title: "District",
          },
          {
            path: "/master/agecategorylist",
            type: "link",
            active: false,
            selected: false,
            title: "Age Category",
          },
          {
            path: "/master/meetcategorylist",
            type: "link",
            active: false,
            selected: false,
            title: "Meet Category",
          },
          {
            path: "/master/entrytypelist",
            type: "link",
            active: false,
            selected: false,
            title: "Entry Type",
          },
        ],
      },
      {
        title: "Athletes",
        icon: "fa fa-user",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/superadmin/pendingathletes",
            type: "link",
            active: false,
            selected: false,
            title: "Pending Athletes",
          },
          {
            path: "/superadmin/approvedathletes",
            type: "link",
            active: false,
            selected: false,
            title: "Approved Athletes",
          },
          {
            path: "/superadmin/rejectedathletes",
            type: "link",
            active: false,
            selected: false,
            title: "Rejected Athletes",
          },
          {
            path: "/superadmin/blockedathletes",
            type: "link",
            active: false,
            selected: false,
            title: "Blocked Athletes",
          },
          {
            path: "/superadmin/holdathletes",
            type: "link",
            active: false,
            selected: false,
            title: "Hold Athletes",
          },
          {
            path: "/superadmin/allathletes",
            type: "link",
            active: false,
            selected: false,
            title: "All Athletes",
          },
          {
            path: "/superadmin/pendingdoc",
            type: "link",
            active: false,
            selected: false,
            title: "Pending Doc List",
          },
          {
            path: "/superadmin/deletedathlete",
            type: "link",
            active: false,
            selected: false,
            title: "Deleted Athletes",
          },
          {
            path: "/superadmin/docupdate",
            type: "link",
            active: false,
            selected: false,
            title: "Document Updated Athletes",
          },
          {
            path: "/superadmin/similar",
            type: "link",
            active: false,
            selected: false,
            title: "Similar Profiles",
          },
          {
            path: "/superadmin/missingpayment",
            type: "link",
            active: false,
            selected: false,
            title: "Update Missing Payments",
          },
          // {
          //   path: "/superadmin/addathlete",
          //   type: "link",
          //   active: false,
          //   selected: false,
          //   title: "Add Athlete",
          // },
        ],
      },
      {
        title: "Coaches",
        icon: "fa fa-users",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/superadmin/pendingcoaches",
            type: "link",
            active: false,
            selected: false,
            title: "Pending Coaches",
          },
          {
            path: "/superadmin/approvedcoaches",
            type: "link",
            active: false,
            selected: false,
            title: "Approved Coaches",
          },
          {
            path: "/superadmin/rejectedcoaches",
            type: "link",
            active: false,
            selected: false,
            title: "Rejected Coaches",
          },
          {
            path: "/superadmin/blockedcoaches",
            type: "link",
            active: false,
            selected: false,
            title: "Blocked Coaches",
          },
          {
            path: "/superadmin/allcoaches",
            type: "link",
            active: false,
            selected: false,
            title: "All Coaches",
          },
          {
            path: "/superadmin/coachdocupdate",
            type: "link",
            active: false,
            selected: false,
            title: "Document Updated Coaches",
          },
        
        ],
      },
      {
        title: "Approved & Rejected",
        icon: "fa fa-user-o",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/superadmin/approvedrejectlist",
            type: "link",
            active: false,
            selected: false,
            title: "Approved & Rejected Count",
          },
        ]

      },
      {
        title: "Meet",
        icon: "fa fa-calendar",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/superadmin/listmeet",
            type: "link",
            active: false,
            selected: false,
            title: "List Meet",
          },
          {
            path: "/superadmin/addmeet",
            type: "link",
            active: false,
            selected: false,
            title: "Add Meet",
          },
          {
            path: "/superadmin/managercourselist",
            type: "link",
            active: false,
            selected: false,
            title: "Manager Course Registration List",
          },
        ],
      },
      
      {
        title: "Results",
        icon: "fa fa-list-alt",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/superadmin/addresult",
            type: "link",
            active: false,
            selected: false,
            title: "Add Results",
          },
          {
            path: "/superadmin/resulthistory",
            type: "link",
            active: false,
            selected: false,
            title: "History",
          },
          
        ],
      },
      {
        title: "Over Age",
        icon: "fa fa-child",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/superadmin/addoverage",
            type: "link",
            active: false,
            selected: false,
            title: "Add Over Age",
          },
          {
            path: "/superadmin/overagehistory",
            type: "link",
            active: false,
            selected: false,
            title: "History",
          },
          
        ],
      },
      {
        title: "Certificates",
        icon: "fa fa-certificate",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/superadmin/certificatedashboard",
            type: "link",
            active: false,
            selected: false,
            title: "Dashboard",
          },
          {
            path: "/superadmin/importdata",
            type: "link",
            active: false,
            selected: false,
            title: "Import Data",
          },
          {
            path: "/superadmin/generatecertificate",
            type: "link",
            active: false,
            selected: false,
            title: "Generate Certificate",
          },
        ],
      },      
      // {
      //   path: "/superadmin/activieslog",
      //   icon: "fa fa-folder",
      //   type: "link",
      //   active: true,
      //   selected: true,
      //   title: "Admin Activies Log",
      // },
      // {
      //   path: "/superadmin/athletestatus",
      //   icon: "fa fa-book",
      //   type: "link",
      //   active: true,
      //   selected: true,
      //   title: "Athlete Status",
      // },
      {
        title: "NOC",
        icon: "fa fa-user",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/superadmin/nocathletelist",
            type: "link",
            active: false,
            selected: false,
            title: "Athlete List",
          },
          {
            path: "/superadmin/nocapproved",
            type: "link",
            active: false,
            selected: false,
            title: "Approved Athletes",
          },
          {
            path: "/superadmin/nocrejected",
            type: "link",
            active: false,
            selected: false,
            title: "Rejected Athletes",
          },
        ],
      }, 
      {
        title: "Reapply IDCard",
        icon: "fa fa-address-card",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/superadmin/pendingidcard",
            type: "link",
            active: false,
            selected: false,
            title: "Pending IDCard",
          },
          {
            path: "/superadmin/printedidcard",
            type: "link",
            active: false,
            selected: false,
            title: "Printed IDCard",
          },
          {
            path: "/superadmin/deliveryidcard",
            type: "link",
            active: false,
            selected: false,
            title: "Delivered IDCard",
          },
          
        ],
      }, 
      {
        path: "/superadmin/paymentlist",
        icon: "fa fa-credit-card",
        type: "link",
        active: true,
        selected: true,
        title: "Payment",
      },
      {
        title: "IDCard",
        icon: "fa fa-address-card",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/superadmin/inhandidcard",
            type: "link",
            active: false,
            selected: false,
            title: "Inhand IDCard",
          },
          {
            path: "/superadmin/deliveredidcard",
            type: "link",
            active: false,
            selected: false,
            title: "Delivered IDCard",
          },
        ],
      }, 
    ],
  },
];
